/* behavior */

.item-details-image {
  position: absolute;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  /* next line is portrait-only */
  height: 100vw;
  background: transparent none no-repeat 50% 0;
  background-size: cover;
}

.item-details-card-header h2,
.item-details-card-header h3,
.item-details-card-header h4 {
  margin-top: 0;
}

.item-details-actions {
  position: absolute;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.item-details-back {
  position: absolute;
  position: fixed;
  left: 16px;
  top: 16px;
}

.ios .item-details-back {
  top: 28px !important;
}

.item-details-back ion-button {
  --border-radius: 30px;
  width: 45px;
  height: 45px;
}

/* end behavior */

/* content reset */

ion-button.item-details-add-to-order {
  margin-top: 0;
  margin-bottom: 0;
}
c ion-button.item-details-add-to-order.greyed {
  --background: var(--ion-color-gray-tint);
}

/* end content reset */

/* design */

.item-details-wrapper .no-padding {
  background: var(--ion-color-light);
}

.item-details-content {
  display: flex;
  flex-direction: column;
}

.item-details-content-box {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.item-details-description {
  align-items: center;
  margin-left: 40px;
  margin-top: -5px;
}

.item-details-content-box-checkbox {
  display: flex;
  flex: 1 0 auto;
}

ion-label.product-name {
  width: 100%;
  margin-left: 10px;
}

.item-details-card {
  background-color: #fff;
  border-top-left-radius: var(--ion-card-radius);
  border-top-right-radius: var(--ion-card-radius);
}

.item-details-card-header {
  padding: 10px var(--ion-padding) 10px;
  z-index: 1;
}

.item-details-card-header .title {
  font-weight: bold;
  margin: 0;
  display: flex;
  align-items: center;
}

.item-details-card-content .sectiontitle {
  font-family: var(--ion-default-font-medium);
  margin: 0;
}

@media (max-width: 1000px) {
  .item-details-card-content .sectiontitle {
    font-size: 12px;
  }
}

.item-details-card-description,
.item-details-card-description p,
.item-details-card-description p span {
  color: var(--ion-color-gray) !important;
}

.item-details-card-price {
  text-align: right;
  font-size: var(--okx-title-font-size);
  font-weight: normal;
  color: var(--ion-color-primary);
}

.item-details-card-content {
  padding: 0 var(--ion-padding);
  overflow-y: auto;
}

@media screen and (max-width: 360px) {
  .item-details-card-content {
    padding: 0 10px;
  }
}

.item-details-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  padding: 10px 24px 24px;
}

.item-details-actions-quantity {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-details-remove,
.item-details-add {
  flex: 0 0 40px;
  height: 40px;
  --padding-start: 0;
  --padding-end: 0;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-light);
  --background: #fff;
  --background-hover: var(--ion-color-light);
  --color: var(--ion-color-primary);
}

.item-details-quantity {
  flex: 0 0 75px;
  text-align: center;
}

.item-details-background {
  background: var(--ion-color-gray) url(../../assets/images/logo-main1.jpg) no-repeat 50% 50%/110%
    50%;
  background-size: cover; /* This is addition. If isn't necessarily remove it. */
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  /* next line is portrait-only */
  opacity: 0.7;
  height: 100vw;
}

.item-details-product-label {
  line-height: normal;
}

.item-wrapper > div.sub-item:nth-of-type(1) > .price-increment-content {
  /* background-color: red; */
  padding-bottom: 10px;
}

.sub-item {
  display: flex;
  align-items: flex-start;
}

.sub-item ion-item,
.sub-item-grow ion-item {
  align-self: flex-start;
}

.details {
  display: flex;
  align-self: flex-start;
  /* align-self: center; */
  margin-top: 8px;
}

.details + ion-label {
  text-transform: uppercase;
}

.details + ion-label ion-text {
  font-weight: bold;
}

.price-increment-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /*background: 'red'*/
  align-self: flex-start;
  height: 100%;
  flex-direction: column;
  flex: 1 1 100%;
  /* padding-top: 10px; */
}

@media screen and (max-width: 360px) {
  .price-increment-content p {
    margin-right: 10px;
  }
}

.price-increment-content p {
  margin-bottom: 20px;
}

.incrementer-box {
  width: 130px;
  /* padding-bottom: 10px; */
  align-items: flex-start;
  height: 100%;
  margin-top: -15px;
}

.incrementer-box-top {
  width: 130px;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 10px;
}

.incrementer-box-top > ion-button {
  margin-bottom: 0;
}

.sub-item {
  display: flex;
  align-items: center;
}

.sub-item ion-item {
  flex: 0 0 180px;
  /* background: red; */
}

.web .sub-item ion-item .details,
.web .sub-item-grow ion-item .details {
  /* flex: 0 0 180px; */
  /* background: red; */
  margin-top: 13px;
  margin-bottom: 5px;
  margin-right: 30px;
}

.sub-item-grow ion-item {
  flex: 1 1 100%;
}

ion-label.up {
  display: flex;
  align-self: flex-start;
}

.sub-item--price {
  display: flex;
  align-items: flex-start;
}

.sub-item--price ion-checkbox {
  margin-top: 0;
  margin-bottom: 5px;
}

.sub-item ion-radio,
.sub-item-grow ion-radio {
  border: none;
  border-radius: 30px;
  height: 21px;
  width: 21px;
}

.ios .sub-item ion-radio,
.ios .sub-item-grow ion-radio {
  width: 26px;
  height: 26px;
}

.primary-radio {
  background: var(--ion-color-primary) !important;
}

.sub-item-price {
  display: flex;
  justify-content: flex-end;
}

@supports (-webkit-overflow-scrolling: touch) {
  /* iOS */
  .item-details-image {
    position: absolute;
  }
  .item-details-back {
    position: absolute;
  }
}

.small-title {
  font-size: 18px !important;
}

/* end design */
