.description {
  --color: #284734;
  font-size: 15px;
}

.pay-item {
  margin-top: 30px;
}

.price-item > h2 {
  margin-top: auto !important;
  font-size: 14px;
  font-weight: 700;
  color: #284734;
}

.price-item > h3 {
  font-size: 14px;
  color: #284734;
}

.item-name ion-item ion-label {
  --color: #284734 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.select-btn {
  margin-top: 80px;
  --background: #fff;
  --border-radius: 20px;
  --border-width: 2px;
  --color: #284734;
  --border-color: #284734;
  --border-style: solid;
}

.pay-btn {
  --background: #d78825;
  --border-radius: 20px;
  --color: #fff;
  margin-top: 80px;
}

.number-items {
  display: flex;
  width: 65px;
  justify-content: space-between;
}

.line {
  background-color: #284734 !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.cart-item {
  float: right;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}

.modal-btns {
  display: flex;
  justify-content: space-between;
}

.table-check .modal-wrapper {
  height: 190px !important;
}

.table-check .modal-classic-content {
  overflow-y: hidden !important;
}

.table-check h3 {
  font-size: 18px !important;
  text-align: center;
}

.table-check.modal-classic .modal-classic-content {
  padding: 0px !important;
}

.table-check ion-label {
  font-size: 15px !important;
  text-align: center;
}

.voucher-modal ion-item {
  padding: 10px !important;
  text-align: center;
}

.voucher-modal ion-button {
  height: 20px !important;
}

.voucher-modal .table-check ion-item {
  padding: 10px !important;
}

.voucher-modal .modal-classic-content {
  padding: 0px !important;
}

.subitem.pay-items {
  margin: 10px 0 10px 10px;
  display: flex;
  justify-content: space-between;
}

.route-pay-items ion-col {
  color: var(--ion-color-primary);
}
.route-pay-items .disabled,
.route-pay-items .disabled * {
  opacity: 0.5;
}
.table-item-wrapper ion-checkbox {
  margin-right: 10px;
}
.table-item-wrapper .empty-item {
  margin-left: 30px;
}
