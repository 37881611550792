ion-modal.modal-classic.apply-voucher-modal {
  --width: 80%;
  --height: 45%;
  --border-radius: 10px;
}

.web ion-modal.modal-classic.apply-voucher-modal {
  --width: 500px;
  --height: 45%;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-wrapper {
  padding: 0;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-header {
  background-color: var(--ion-color-secondary);
  padding-left: 20px;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-header h3 {
  color: var(--ion-color-white);
  text-transform: uppercase;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-closer {
  color: var(--ion-color-white);
  padding: 0px;
  top: 5px;
  right: 5px;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-content {
  padding: 15px 15px;
}

ion-modal.modal-classic.apply-voucher-modal .modal-classic-content > .flex-row-wrapper {
  height: 100%;
}

.apply-voucher-modal.voucher-modal-content {
  padding: 0 30px;
}

.voucher-item ion-item ion-input {
  text-transform: capitalize;
  color: black;
}

ion-button.box-button-color {
  --background: var(--ion-color-gray-tint);
}

ion-button.box-button-color-tertiary {
  --background: var(--ion-color-tertiary);
}

.apply-voucher {
  margin-bottom: 20px;
  border: 2px solid var(--ion-color-secondary);
}

.web .apply-voucher {
  margin-bottom: 20px;
  border: 2px solid var(--ion-color-secondary);
}

.web .apply-voucher,
.web .apply-voucher-box {
  cursor: pointer;
}

.web ion-content.route-apply-vouchers {
  --background: transparent none;
  --ion-color-white: transparent;
}

.web ion-content.route-apply-vouchers .ion-padding > div ion-button {
  margin: 10px 0 !important;
  text-transform: none !important;
}
