.toggle {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.toggle + div > ion-toggle[role='checkbox'] {
  padding-left: 0;
}

.register-background {
  /* background: url(../../assets/images/HolyCow-main-background.png) transparent no-repeat 50% 100%; */
  background-size: 100% auto;
}

.box-holder.register-box-holder {
  background: rgba(255, 255, 255, 0.3);
  padding: 15px;
  border: 2px solid var(--ion-color-primary);
}

.web ion-content.route-register {
  --background: transparent;
  --ion-color-base: transparent;
}

.web #main > ion-content.route-register > .ion-padding {
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: none;
  padding: 32px;
  min-height: 630px;
  max-height: 75%;
  overflow-y: auto;
}

.web #main > ion-content.route-register > .ion-padding > .absolute-content {
  padding: 32px;
}

@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-register > .ion-padding {
    min-height: 650px;
    max-height: 65%;
  }
}

@media screen and (max-height: 720px) {
  .web #main > ion-content.route-register > .ion-padding {
    min-height: calc(100% - 125px);
    box-shadow: none;
  }
}

.register-text-wrap {
  display: inline !important;
}

.text-header {
  overflow: unset;
}
