/* work */
ion-content div.restaurant-gradient {
  background-image: url(../../assets/images/Click&Collect.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: 100% auto;
}

ion-content div.restaurant-gradient:before {
  content: 'Click & Collect';
  position: absolute;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  font-family: var(--ion-second-font);
  font-size: 16px;
  border-radius: var(--ion-card-radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  top: 0;
  left: 0;
  z-index: 5;
}

/* ion-content div.restaurant-gradient::before {
	content: '';
	display: block;
	position: absolute;
	height: 55%;
	left: 0; top: 0; right: 0; bottom:85%;
	z-index: 1;
	background-image: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0));
} */

/* don't work
.restaurant-gradient::before {
	background-image: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0));
}

.restaurant-gradient::before {
	content: '';
	display: block;
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	z-index: 1;
	background-image: url(../../assets/images/Click&Collect.png);
	background-repeat: no-repeat;
	background-position: 50% 0;
	background-size: 150% auto;
}
*/

.restaurant-card {
  border-radius: var(--ion-card-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: 0;
}

.restaurant-card ion-card-content {
  padding: 30px;
  font-size: var(--ion-font-size);
}

.restaurant-card ion-card-content .restaurant-info-title {
  padding: 0 2px;
  font-weight: bold;
}

.restaurant-card ion-card-content .restaurant-info {
  padding: 15px 15px 30px;
  text-align: center;
}

.click-collect-pickers-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.3;
  height: 100%;
  width: 100%;
  z-index: 3;
}

.click-collect-dialog {
  position: fixed;
  margin: 0 auto;
  background-color: #fff;
  width: 70%;
  height: 50%;
  border-radius: 10px;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -45%);
  transition: all 0.3s ease-out;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 999;
}

.click-collect-dialog.show-up {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, -50%);
  pointer-events: auto;
}

.click-collect-dialog-layout {
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 5px 13px;
}

.click-collect-dialog.sc-ion-modal-md {
  border-radius: var(--border-radius);
  width: var(--width);
  min-width: var(--min-width);
  max-width: var(--max-width);
  height: var(--height);
  min-height: var(--min-height);
  max-height: var(--max-height);
  border-width: var(--border-width);
  border-style: var(--border-style);
  border-color: var(--border-color);
  background: var(--background);
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  overflow: var(--overflow);
  z-index: 10;
}

/* .click-collect-dialog.sc-ion-modal-md {
	-webkit-transform: translate3d(0,40px,0);
	transform: translate3d(0,40px,0);
	opacity: .01;
} */

.click-collect-dialog-header {
  flex: 0 1 auto;
  padding: 10px 5px;
}

.click-collect-dialog-header h3 {
  font-size: 14px;
  font-weight: 100;
  margin: 0;
}

.click-collect-dialog-closer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}

.click-collect-dialog-closer ion-icon {
  font-size: 20px;
}

.click-collect-dialog-content {
  flex: 1 1 auto;
  padding: 12px;
  overflow-y: auto;
}

.click-collect-dialog-content label {
  color: var(--ion-color-black);
  font-size: 10px;
  font-weight: 100;
  align-self: center;
}

.click-collect-dialog-content label.time-picker-label {
  font-size: 10px;
}

.click-collect-dialog-content .mbsc-select-input.mbsc-control {
  font-size: 11px;
  font-weight: 100;
  color: var(--ion-color-black);
  border-bottom: 1px solid var(--ion-color-light);
  padding-left: 3px;
}
.click-collect-dialog-action {
  flex: 0 1 auto;
}

.click-collect-dialog-action ion-button {
  height: 36px;
}

.click-collect-dialog-content ion-label .sectiontitle {
  margin: 0;
  line-height: 18px;
}

/*
@keyframes popupAnimation {
	0% {

	}

	75% {

	}

	100% {

	}
}
*/

.delivery-option-label {
  position: absolute;
  top: 30px;
  left: 64px;
  right: 64px;
  text-align: center;
  z-index: 3;
}

.web .static.route-click-and-collect {
  background: transparent none;
  background-image: url(../../assets/images//Click&Collect.jpg);
  background-size: cover;
  background-position: 100% 0%;
  background-repeat: no-repeat;
}

.web ion-content div.restaurant-gradient:before {
  display: none;
}

.web ion-content.route-click-and-collect div.restaurant-gradient {
  background-image: none;
}

.web ion-content.route-click-and-collect {
  --background: transparent none;
  background-color: #fff;
  background-image: url(../../assets/images/Click&Collect.jpg);
  background-position: 100% 0%;
  background-size: cover;
  background-repeat: no-repeat;
  /* --ion-color-white: transparent; */
}

.web .route-click-and-collect .restaurant-card {
  top: 50%;
  bottom: auto;
  left: 50%;
  right: auto;
  position: fixed;
  transform: translate(-50%, -50%);
  width: var(--okx-bsmodal-width-lg);
  max-width: var(--okx-bsmodal-width-lg);
  min-width: 280px;
  padding: 64px 64px 64px;
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--okx-box-radius);
  background-color: var(--ion-color-white);
}

.web .restaurant-menu ion-item {
  --color: var(--ion-color-white);
  --background: var(--ion-color-black);
  margin-bottom: 20px;
  border-radius: var(--okx-small-radius);
}

.web .restaurant-card ion-card-content {
  padding: 0;
}

.web .restaurant-card ion-card-content .restaurant-info {
  padding: 15px 0 0;
}

.web .click-collect-dialog {
  width: var(--okx-bsmodal-width-lg);
  height: 45%;
}

.web .click-collect-dialog-layout {
  padding: 32px 64px 48px;
}

.web .mbsc-select-input.mbsc-control {
  border-color: var(--ion-color-light);
}

.web .click-collect-dialog-header h3 {
  font-size: 22px;
  /* text-transform: uppercase; */
}

.web .mbsc-fr-popup {
  left: 50%;
}

/* .web .content-spinner {
	background-color: transparent;
} */

.web #main > ion-content.route-click-and-collect .delivery-option-label {
  display: none;
}

.web #main > ion-content.route-click-and-collect .delivery-option-label.web-only {
  display: block;
  top: 64px;
}

.round-button {
  --border: 1px solid;
  --border-radius: 20px;
  height: 40px !important;
}
