.loyalty-content {
  height: 100%;
  text-align: center;
  padding: 20px;
  overflow-y: auto;
}
​ .qr-holder {
  margin-top: 40px;
}
​ .loyalty-content.scan h2 {
  margin-top: 20px;
}
​
/* .loyalty-content h2 {
	margin-top: 0px;
	font-size: 15px;
	font-weight: bold;
	text-transform: uppercase;
} */

/* .loyalty-content h2.points {
	margin-top: 0px;
	padding-top: 0px;
	font-size: 60px;
	font-weight: bold;
}​

.loyalty-content h2.points-balance {
	margin: 0;
} */

.loyalty-content ion-note {
  display: block;
  margin-bottom: 20px;
  margin: 0 auto;
  width: 55%;
}
​ .loyalty-content ion-button {
  margin: 0 20px 20px;
}
​ .loyalty-content ion-card {
  position: relative;
}
​ .loyalty-content ion-card-title {
  font-weight: bold;
  font-size: 22px;
}
​ .loyalty-content ion-card-subtitle {
  text-transform: none;
}
​ .loyalty-content .cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--ion-color-dark);
  opacity: 0.6;
}
​ .loyalty-content .cover ion-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  margin-left: -25px;
  margin-top: -25px;
}
​ .loyalty-image {
  --background: #fff;
  border-radius: 0px;
  padding-bottom: 35%;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  box-shadow: none;
}
​ .loyalty-image-01 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-bottom: 35%;
}

.loyalty-content .cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--ion-color-dark);
  opacity: 0.6;
}

.loyalty-content .cover ion-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  margin-left: -25px;
  margin-top: -25px;
}

.loyalty-content .loyalty-image {
  --background: #fff;
  border-radius: 0px;
  padding-bottom: 50%;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: cover;
  box-shadow: none;
}

.order-intro-modal-content {
  flex: 1 1 auto;
  max-height: 100%;
  /* height: calc(100% - 145px); */
  overflow-y: auto;
}

.order-intro-modal-content .loyalty-image-01 {
  width: 100%;
  /* height: 80px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-bottom: 50%;
  margin-top: 180px;
  margin-bottom: 20px;
}
​ .noQrCode {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

​.voucher-info-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

​.voucher-info-wrapper
  .order-intro-modal
  > .modal-wrapper
  > .ion-page
  > .voucher-info-wrapper
  > .order-intro-modal-content {
  flex: 1 1 auto;
  padding: 0 20px;
}

.order-intro-modal > .modal-wrapper {
  --max-height: 450px;
  width: 300px;
  border-radius: 15px;
}
​ .order-intro-modal > .modal-wrapper {
  height: 390px;
  width: 300px;
  border-radius: 15px;
}
​ .order-intro-modal > .modal-wrapper > .ion-page {
  overflow: auto;
  justify-content: center;
}

.order-intro-modal .header {
  flex: 0 0 auto;
  position: relative;
  background-color: var(--ion-color-primary);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-intro-modal .header > ion-toolbar > ion-title {
  width: auto;
  color: var(--ion-color-white);
  font-size: 16px;
  font-weight: normal;
  text-transform: uppercase;
  padding: 0;
  padding-left: 20px;
  visibility: visible !important;
}

.absolute-content.points-tab {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  background-size: 100% auto;
  overflow-y: auto;
  z-index: 1;
}

.absolute-content.points-tab .points {
  font-family: var(--ion-second-font);
  color: var(--ion-color-secondary);
}

.absolute-content.points-tab > div {
  height: 100%;
}

.web .absolute-content.points-tab > div {
  /* height: calc(100% - 149px); */
  height: 100%;
}

.points-image {
  flex: 1 1 auto;
  width: 50vw;
  margin: 20px auto 30px;
}

.points-footer {
  flex: 0 0 auto;
  left: 0;
  bottom: 0;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-fill-available;
  width: -moz-available;
  width: stretch;
  background: var(--ion-color-primary);
  border-top-left-radius: var(--ion-card-radius);
  border-top-right-radius: var(--ion-card-radius);
  padding: 20px 0 40px;
  margin: 0 2px;
  z-index: 2;
}

.web .points-image {
  width: max(1rem, 35%);
  /* height: calc(100% - 149px); */
}

.web .points-footer {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
}

.points-footer ion-text > .small-text,
.points-footer ion-text > .normal-text {
  color: var(--ion-color-white);
  font-weight: bold;
}

.order-intro-modal ion-toolbar.dynamic-header {
  margin-top: 0;
}
​ .header {
  background-color: var(--ion-color-primary);
}
​ .modal-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}
​ .modal-button {
  margin-top: 35px;
  padding: 0 15px;
}
​
​
/* second tab */
​
.scan-holder {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: hidden;
}
​ .loyalty-content.scan {
  padding-bottom: 60px;
}
​ .loyalty-content.scan h2 {
  margin-top: 20px;
}

​.voucher-info-wrapper .flex-min {
  flex: 0 0 auto;
}
​ .noQrCode {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.noQrCode h5 {
  text-align: center;
}
​
/* end second tab */
​
.points-tab {
  /* background: transparent url(../../assets/images/HolyCow-main-background.png) no-repeat 50% 100%; */
  background-size: 100% auto;
  z-index: 1;
}

.loyalty-content .points {
  font-size: 3rem;
  font-family: var(--ion-font-family);
  letter-spacing: 2px;
}

.loyalty-content .points-subtitle {
  color: var(--ion-color-primary);
  font-size: 1.5rem;
  font-family: var(--ion-font-second);
  margin: 0 0 5px 0;
  text-transform: uppercase;
}
​

/* .points-tab::after {
	position: absolute;
	left: 0; top: 0; right: 0; bottom: 0;
	display: block;
	content: '';
	background-image: linear-gradient(#fff 65%, rgba(255,255,255,0) 105%);
} */
​
.verified-content {
  width: 190px;
  margin: 0 auto;
}

.web #main ion-content.route-loyalty {
  --background: transparent none;
  --ion-color-base: transparent;
}

.web .voucher-info-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
}

.web .voucher-info-image {
  max-height: 200px;
  height: 200px;
  background-size: cover;
  margin-top: 20px;
}

.web .voucher-info-wrapper .header ion-toolbar.dynamic-header {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}

.web .voucher-info-wrapper .header > ion-toolbar > ion-title {
  text-align: left !important;
}

.web #main > ion-content.route-loyalty > .no-padding {
  position: fixed;
  left: 50%;
  top: 100px;
  bottom: 100px;
  width: 400px;
  margin-left: -200px;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  min-height: 450px;
}

.web .route-loyalty .tabs {
  position: relative;
  height: 100%;
  border-radius: inherit;
}

.web .route-loyalty .tabs ion-slides,
ion-slide {
  height: calc(100vh - 350px);
  min-height: 350px;
}

.web ion-content.route-loyalty {
  --background: transparent none;
  --ion-color-base: transparent;
  background-color: #fff;
  background-image: url(../../assets/images/dashboard-03.jpg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.web ion-segment-button.segment-button-checked {
  border-bottom-color: var(--ion-color-secondary);
}

.web .points-tab,
.web .points-tab::before {
  background: transparent none;
}
