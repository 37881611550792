.pick-up-point-check-bg {
  --background: transparent none;
  --ion-color-white: transparent;
  background-color: #fff;
  background-image: url(../../assets/images/Click&Collect.jpg);
  background-repeat: no-repeat;
  background-position: 100% 0%;
  background-size: 100% auto;
}

.code-checking-box {
  min-height: 80px;
}

.route-pick-up-point-check .restaurant-card {
  position: absolute;
  top: 0;
  border-radius: 0;
}
ion-app:not(.web) .route-pick-up-point-check .restaurant-card {
  position: absolute;
  top: 0px;
  border-radius: 0;
}
.route-pick-up-point-check .restaurant-gradient,
.route-pick-up-point-check div.restaurant-gradient::before {
  background-image: none;
}

.route-pick-up-point-check .restaurant-card > ion-card-content {
  height: 100%;
}

#main > ion-content.route-pick-up-point-check {
  z-index: 10;
}
.pup-modal-inner-wrapper {
  height: 100%;
}

.pup-modal-info-holder {
  height: var(--okx-pup-map-wrapper-top);
  overflow-y: auto;
  text-align: center;
}
.web ion-content.route-pick-up-point-check {
  --background: transparent;
}

.web .route-pick-up-point-check .restaurant-card {
  padding-top: 64px;
}

.web #main > ion-content.route-pick-up-point-check > .no-padding .restaurant-gradient {
  background: transparent none;
}

.web .route-pick-up-point-check .delivery-option-label {
  display: none;
}
