.input-avatar {
  display: none;
}

.profile-image-content {
  width: 96px;
  height: 96px;
  margin: auto;
}

.avatar-image-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.avatar-image-border {
  border: 2px solid var(--ion-color-primary);
  border-radius: 50%;
}

.avatar-delete {
  position: absolute;
  top: 10%;
  right: 115px;
  transform: translate(0, -50%);
}

.avatar-photo {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translate(0, -50%);
  background: rgba(var(--ion-color-primary-rgb), 0.99);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-circle {
  border: 10px solid var(--ion-color-white);
  border-radius: 65px;
  position: relative;
}

.line {
  height: 1px;
  background-color: var(--ion-color-tertiary);
  margin: 20px 0;
}

.top {
  margin-top: 25px;
}

.account-inputs > ion-item > ion-input,
.account-inputs > div > input {
  font-family: var(--ion-default-font-medium) !important;
  font-weight: var(--okx-text-bold);
  color: var(--ion-color-primary);
  /* text-transform: var(--okx-text-uppercase); */
  margin-left: 2px;
}

.account-inputs > .mbsc-select-input.mbsc-control {
  font-family: var(--ion-default-font-medium) !important;
  font-size: var(--ion-font-size);
  font-weight: var(--okx-text-bold);
  color: var(--ion-color-primary);
  border-bottom: 0;
}

.account-inputs > ion-item > ion-input.email-input {
  text-transform: var(--okx-text-uppercase);
}

.account-inputs > ion-item.item > ion-label.sc-ion-label-ios-h,
.account-inputs > label:not(.select-picker-labe) {
  position: relative;
  top: 13px;
  font-family: var(--ion-font-family);
  font-weight: 300 !important;
  color: var(--ion-color-gray) !important;
  text-transform: initial;
}

.account-inputs .date-picker-label {
  position: relative;
  top: 9px;
  font-weight: 300;
}

.account-inputs > label.select-picker-label,
.account-inputs > label.date-picker-label,
.account-inputs > label.select-picker-label {
  position: relative;
  top: 9px;
  font-family: var(--ion-font-family);
  font-size: 10px;
  font-weight: 300 !important;
  color: var(--ion-color-gray) !important;
}

.account-inputs > label.select-picker-label + input.mbsc-select-input.mbsc-control {
  border-bottom: 0;
}

.account-inputs > ion-item > ion-button.link {
  font-family: var(--ion-font-family);
  font-size: var(--okx-small-text-font-size);
  font-weight: 100;
  text-transform: initial;
}

.account-inputs ion-item > ion-label.ion-text-wrap {
  top: 0 !important;
}

.account-inputs > div.subtitle,
.account-inputs ion-item > ion-label.ion-text-wrap > ion-note {
  color: var(--ion-color-primary);
}

.avatar-image-wrapper + ion-text + ion-text + .validate-button-wrapper {
  margin-top: 40px;
}

@supports (-webkit-overflow-scrolling: touch) {
  /* iOS */
  .avatar-delete {
    position: absolute;
    top: 10%;
    right: 95px;
    transform: translate(0, -50%);
  }
}

.web ion-content.route-account {
  --background: transparent;
  --ion-color-base: transparent;
  background-image: url(../../assets/images/dashboard-03.jpg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.web #main > ion-content.route-account > .ion-padding {
  position: fixed;
  left: 50%;
  top: 50px;
  bottom: 50px;
  width: 450px;
  transform: translate(-50%, 0%);
  margin-left: 0;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .route-account .ion-padding .frm-wrapper,
.web .route-account .ion-padding .top-medium {
  position: absolute;
}

.web .route-account .ion-padding .frm-wrapper {
  left: 32px;
  right: 32px;
  top: 0;
  bottom: 130px;
  margin-top: 32px;
  padding-top: 10px;
  overflow-y: auto;
}

.web .route-account .ion-padding .top-medium {
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 32px 32px;
}

.web .route-account .ion-padding ion-button:last-child {
  margin-bottom: 0;
}

.web .avatar-photo,
.web .avatar-delete {
  cursor: pointer;
}
