.mbsc-select-input.mbsc-control {
  width: 100%;
  color: var(--ion-color-primary);
  font-family: (--ion-font-family);
  font-size: 11px;
  font-weight: 100;
  padding: 10px 8px 10px 0;
  border: 0;
  border-bottom: 1px solid;
  border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );
  outline: none;
}

.mbsc-select-input.mbsc-control::placeholder {
  color: var(--ion-color-dark);
  opacity: 0.33;
}

.mbsc-ios .mbsc-fr-btn {
  color: var(--ion-color-primary, #3880ff);
  font-size: 17px;
}

.select-picker-label {
  display: block;
  font-family: var(--ion-default-font-medium);
  font-size: 9px;
  color: var(--okx-normal-text-color);
  margin-top: 10px;
  margin-left: 2px;
  font-weight: bold;
}

.select-picker-label--primary {
  color: var(--ion-color-primary);
}

.select-picker-label.select-picker-label--feedback {
  position: relative;
  top: 10px;
  margin-left: 0;
}

.select-picker-input.select-picker-input--feedback + input.mbsc-select-input.mbsc-control {
  border-color: var(--ion-color-primary) !important;
}

.mbsc-fr-btn-w .mbsc-fr-btn-s {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-w {
  border: 0;
}

.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-cont {
  border: 1px solid
    var(
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
    );
}

.mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-itm-3d {
  color: #a3a3a3;
}

.mbsc-ios .mbsc-sc-itm-sel {
  color: #000;
  color: var(--ion-color-primary, #3880ff) !important;
}
