ion-content div.refer-a-friend {
  /* background: var(--okx-drawer-background) url(../../assets/images/refer-a-friend.jpg); */
  /* background-repeat: no-repeat; */
  /* background-position: 50% 100%; */
  /* background-position: 50% 20%; */
  /* background-size: initial; */
  background-image: url(../../assets/images/refer-a-friend.jpg);
  background-repeat: no-repeat;
  background-position: 50% auto;
  background-size: auto 70%;
}

/* ion-content div.refer-a-friend::after {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	height: 240px;
	z-index: 1;
	background-image: linear-gradient(rgba(255,255,255,1) 10%, rgba(255,255,255,0) 90%);
} */

.absolute-content.refer-a-friend:before {
  content: attr(title);
  position: absolute;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--ion-color-primary);
  color: white;
  font-family: var(--ion-second-font);
  font-size: 20px;
  border-radius: var(--ion-card-radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  top: 0;
  left: 0;
  z-index: 5;
}

.route-refer-a-friend .title {
  color: var(--ion-color-white);
}

.raf-card {
  border-radius: var(--ion-card-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: 0;
}

.raf-card ion-card-content {
  padding: 30px;
  font-size: var(--ion-font-size);
}

.raf-card ion-card-content .raf-info-title {
  padding: 0 2px;
  font-weight: bold;
}

.raf-card ion-card-content .raf-info {
  font-family: var(--ion-default-font-medium);
  font-size: 15px;
  padding: 15px 15px 30px;
  text-align: center;
  white-space: pre-wrap;
}

.raf-card .inline-input > ion-input {
  --placeholder-opacity: 0.5;
}

/* #main > ion-content.route-refer-a-friend > .ion-padding ion-item {
	--border-color: var(--ion-color-light);
} */

.web .static.route-refer-a-friend {
  background: transparent none;
}

.web .absolute-content.refer-a-friend:before {
  display: none;
}

.web ion-content.route-refer-a-friend {
  --background: transparent none;
  /* background-color: var(--okx-drawer-background); */
  /* background-image: url(../../assets/images/dashboard-03.jpg); */
  background-image: url(../../assets/images/refer-a-friend.jpg);
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  /* --ion-color-white: transparent; */
}

.web #main > ion-content.route-refer-a-friend > .ion-padding .absolute-content {
  background: transparent none;
  border-radius: inherit;
  overflow: hidden;
}

.web .route-refer-a-friend .title {
  color: var(--ion-color-primary);
}

.web #main > ion-content.route-refer-a-friend > .ion-padding {
  max-height: var(--okx-bsmodal-width-sm);
  height: 100%;
}

.web .raf-card {
  border-radius: inherit;
  box-shadow: none;
}
