:root {
  --clickup-initial-height: 50px;
  --clickup-open-height: 50%;
}

.clickup {
  position: absolute;
  position: fixed;
  left: 0;
  top: calc(100% - var(--clickup-initial-height));
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transition: top 200ms ease-out;
  z-index: 5;
}

.clickup.open {
  top: calc(100% - var(--clickup-open-height));
}

.clickup-bar {
  background-color: var(--ion-color-secondary);
  flex: 0 0 var(--clickup-initial-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--ion-default-font-medium);
  cursor: pointer;
}

.clickup-bar > div {
  flex: 1 1 auto;
  text-align: right;
  font-size: 15px;
  text-transform: uppercase;
  color: var(--ion-color-primary-contrast);
}

.clickup-caret {
  height: 20px;
  background: transparent url(../../assets/images/caret-up-white.svg) no-repeat 5px 50%;
}

.clickup.open .clickup-caret {
  background-image: url(../../assets/images/caret-down-white.svg);
}

.clickup-content {
  flex: 1 1 auto;
  background-color: #fff;
  position: relative;
}

.clickup-content .flex-row-wrapper p {
  font-family: var(--ion-default-font-medium);
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 0;
}
