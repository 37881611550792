:root {
  --voucher-red: #6e2b13;
  --voucher-black: #000;
}

.voucher {
  margin-bottom: 20px;
}

.voucher.green .voucher-content {
  background-color: var(--ion-color-primary);
}

.voucher.red .voucher-content {
  background-color: var(--voucher-red);
}

.voucher.black .voucher-content {
  background-color: var(--voucher-black);
}

.voucher-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.voucher-content::before,
.voucher-content::after {
  display: block;
  content: '';
  position: absolute;
  top: 8px;
  bottom: 0;
  width: 20px;
  background: radial-gradient(#fff 0px, #fff 6px, transparent 7px, transparent);
  background-size: 20px 20px;
}

.voucher-content::before {
  left: -9px;
}

.voucher-content::after {
  right: -9px;
}

.voucher-content div {
  color: #fff;
}

.voucher-spacer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20px;
  min-width: 20px;
}

.voucher-info,
.voucher-stamp {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  text-align: center;
}

.voucher-stamp {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 85px;
  min-height: 112px;
  min-width: 85px;
  position: relative;
}

.voucher-stamp::after {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 70px;
  height: 70px;
  margin: -38px 0 0 -38px;
  border-radius: 50%;
  border: 3px #fff solid;
  background: transparent url(../../assets/images/social-icon.svg) no-repeat 50% 50%;
  background-size: 40px 40px;
}

.voucher:not(.default-font) .voucher-title,
.voucher:not(.default-font) .voucher-subtitle {
  font-family: 'Roboto';
}

.voucher-title,
.voucher-subtitle {
  text-transform: uppercase;
}

.voucher-title {
  font-size: 32px;
  font-weight: bold;
}

.voucher-subtitle {
  font-size: 12px;
}

.voucher-description {
  font-size: 8px;
}

.voucher.default-font .voucher-title {
  line-height: 28px;
  font-weight: normal;
}

.voucher.default-font .voucher-subtitle {
  font-weight: bold;
}

.voucher-bar {
  display: flex;
  align-items: center;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.voucher-bar > div:first-child,
.voucher-bar > div:last-child {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.voucher-bar > div:first-child {
  padding-left: 16px;
}

.voucher-bar > div:last-child {
  text-align: right;
  padding-right: 12px;
}

.voucher-bar ion-button {
  margin-bottom: 4px;
  --border-width: 1px;
  --border-color: rgba(var(--ion-color-gray-rgb), 0.5);
  color: var(--ion-color-secondary);
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 3px;
}

.voucher-bar p {
  font-size: var(--okx-small-text-font-size);
}

ion-modal.modal-classic.voucher-modal {
  --width: 85%;
  --height: 70%;
  --border-radius: 10px;
}

ion-modal.modal-classic.voucher-modal .modal-classic-wrapper {
  padding: 0;
}

ion-modal.modal-classic.voucher-modal .modal-classic-header {
  background-color: var(--ion-color-secondary);
  padding-left: 20px;
}

ion-modal.modal-classic.voucher-modal .modal-classic-header h3 {
  color: var(--ion-color-white);
  font-weight: bold;
  text-transform: uppercase;
}

ion-modal.modal-classic.voucher-modal .modal-classic-closer {
  color: var(--ion-color-white);
  font-size: 18px;
}

ion-modal.modal-classic.voucher-modal .modal-classic-content {
  padding: 30px 15px;
}

.voucher-modal-content {
  padding: 0 30px;
}

.modal-classic-content > div > img,
.voucher img {
  object-fit: cover;
  object-position: center;
}

#main
  > ion-content.route-vouchers
  > .ion-padding
  .absolute-content
  .voucher-modal-content
  > ion-text
  > span {
  font-size: 23px;
}

#main > ion-content.route-vouchers > .ion-padding .absolute-content ion-item {
  --border-color: var(--ion-color-light);
}

#main > ion-content.route-vouchers > .ion-padding .absolute-content .inline-input > ion-text {
  flex: 0 0 25%;
}

.voucher-modal-qr {
  /* background: transparent url(../../assets/images/qr-dummy.png) no-repeat 50% 0; */
  background-size: contain;
  width: 100%;
  height: 200px;
}

.voucher-item ion-item ion-input {
  --placeholder-color: var(--ion-color-primary);
  --placeholder-opacity: 1;
  text-transform: initial;
}

.web .static.route-vouchers {
  background: transparent none;
}

.web ion-content.route-vouchers {
  --background: transparent none;
  --ion-color-white: transparent;
  background-image: url(../../assets/images/dashboard-03.jpg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.voucher-image {
  width: 100%;
}

.web #main > ion-content.route-vouchers > .ion-padding .absolute-content {
  border-radius: inherit;
  overflow: hidden;
  padding: 0;
}

.web #main > ion-content.route-vouchers > .ion-padding .absolute-content .flex-min:first-of-type {
  padding: var(--ion-padding) var(--ion-padding) 0 var(--ion-padding);
}

.web #main > ion-content.route-vouchers > .ion-padding .absolute-content > .scrollable-y {
  padding: 0 var(--ion-padding);
}

.web .clickup {
  position: absolute;
}

.voucher-wrapper {
  max-height: 86%;
  margin-bottom: 50px;
  overflow-y: auto;
}
