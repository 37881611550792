/* .delivery-address-add > div.scrollable-y > ion-list > ion-item > ion-input { */
  /* direction: LTR;
  unicode-bidi: bidi-override; */
  /* writing-mode: vertical-rl;
  text-orientation: upright; */
/* } */

/* .delivery-address-add > div.scrollable-y > ion-list > ion-item > ion-input > input.native-input.sc-ion-input-ios:invalid { */
  /* direction: ltr;
  unicode-bidi: bidi-override; */
  /* writing-mode: vertical-rl;
  text-orientation: upright; */
/* } */

ion-content.route-delivery-address-add ion-textarea > div > textarea.native-textarea.sc-ion-textarea-ios {
  padding: 0px;
  padding-top: 7px;
}

ion-content.route-delivery-address-add ion-item {
  --border-color: var(--ion-color-light);
}

.web ion-content.route-delivery-address-add {
	--ion-color-base: transparent;
	--background: transparent;
}

.web #main > ion-content.route-delivery-address-add .ion-padding {
  position: fixed;
  left: 50%;
  top: 50%;
  bottom: unset;
  width: var(--okx-bsmodal-width-lg);
  transform: translate(-50%, -50%);
  min-height: 450px;
  margin-left: 0;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0,0,0,0.25)
}

.driver-notes ion-item > ion-text{
  width: 90px;
  font-weight: bold;
  color: var(--ion-color-black);
}