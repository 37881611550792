.order-content-wrapper ion-row,
.order-content-wrapper .basket-item-wrapper ion-row {
  color: var(--ion-color-primary);
  font-weight: bold;
}

/* .order-content-wrapper ion-row {
	color: var(--ion-color-primary);
	font-weight: bold;
} */

.order-content-wrapper .basket-item-wrapper {
  border-bottom: 1px solid var(--okx-border-color);
}

.order-content-wrapper > ion-row > ion-col > ion-text > span {
  /* color: var(--ion-color-primary); */
  font-size: 14px;
  font-weight: bold;
}

ion-col.grow {
  /* flex-grow: 3.5; */
  flex-grow: 3;
}

ion-modal.modal-classic.remove-apply-voucuher {
  --width: 70%;
  --height: 30%;
  --border-radius: 10px;
}

.web ion-modal.modal-classic.remove-apply-voucuher {
  --width: 500px;
  --height: 15vh;
}

.web ion-modal.modal-classic.remove-apply-voucuher .modal-classic-header {
  height: 100%;
  display: grid;
  place-content: center;
  text-transform: uppercase;
}
