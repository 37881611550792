/* .web .login-background { */
/* --background: url(/static/media/login-01.15375a03.png) transparent no-repeat 50% 100% / 100% auto; */
/* } */
.web ion-content.route-login {
  --background: transparent;
  --ion-color-base: transparent;
  background-size: cover;
  background-image: url(../../assets/images/Click&Collect.jpg);
}

.new-user {
  color: var(--ion-color-primary);
  margin-right: 5px;
}

.web #main > ion-content.route-login > .ion-padding {
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  padding: 32px;
  min-height: 500px;
  max-height: 75%;
}

.web #main > ion-content.route-login > .ion-padding > .absolute-content {
  padding: 32px;
  overflow-y: auto;
}

@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-login > .ion-padding {
    max-height: 60%;
  }
}

@media screen and (max-height: 600px) {
  .web #main > ion-content.route-login > .ion-padding {
    min-height: calc(100% - 125px);
    box-shadow: none;
  }
}
