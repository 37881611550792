ion-content.route-delivery-options {
  width: 100%;
  height: 100%;
  height: calc(100vh - 79px);
  background-image: url(../../assets/images/Click&Collect.jpg);
  /* background-position: 50% 100%; */
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-y: hidden;
}

.web .order-at-table-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(../../assets/images/dashboard-03.jpg);
  background-image: url(../../assets/images/dashboard-03.jpg);
  background-repeat: no-repeat;
  background-position: 45% 100%;
  background-size: 120% auto;
}

ion-content.route-delivery-options::before {
  content: '';
  display: block;
  height: 100%;
  background-image: linear-gradient(rgba(000, 000, 000, 1), rgba(000, 000, 000, 0));
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 85%;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  z-index: -1;
}
.restaurant-select {
  margin-top: 10px;
  margin-bottom: 30px;
}
.table-options-card {
  box-shadow: none;
  margin: var(--ion-padding) !important;
}
.table-options-card ion-card-content {
  padding: 0;
}
.web .table-options-card ion-card-content {
  padding: var(--ion-padding);
}
.web .table-options-card {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  min-width: 280px;
  margin: 0;
  z-index: 2;
  --background: #fff;
  border-radius: var(--ion-card-radius);
  transform: translate(-50%, -50%);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.card-body ion-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-body label.time-picker-label {
  font-size: 10px;
}

.card-body .mbsc-select-input.mbsc-control {
  font-size: 11px;
  font-weight: 100;
  color: var(--ion-color-black);
  border-bottom: 1px solid var(--ion-color-light);
  padding-left: 3px;
}

.text-label {
  font-size: 13px;
  color: #000 !important;
  font-weight: 700;
  margin-top: 40px;
}

.next-button {
  --height: 50px !important;
  --border-radius: 30px !important;
  width: 100%;
  margin-top: 40px;
}

.web #main > ion-content.route-delivery-options {
  --background: transparent none;
  --ion-color-base: transparent;
  background-size: cover;
}

.web ion-content.route-delivery-options::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  z-index: -1;
}

.card-title {
  color: var(--ion-color-primary) !important;
  font-size: 17px !important;
  margin-bottom: 10px;
}

.mbsc-fr-popup mbsc-ltr {
  position: absolute;
  top: 50%;
  left: 50%;
}
