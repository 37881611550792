.history-content {
  text-align: center;
  padding: 20px;
  /* position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto; */
}

.history-item {
  border-bottom: 1px solid var(--ion-separator-color);
  text-transform: uppercase;
}

.history-item h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  /* --ion-font-family: 'Avenir-Book'; */
  font-size: 15px;
  font-weight: bold;
  color: var(--ion-color-primary);
}

.history-item ion-col:first-child {
  text-align: left;
}

.history-item ion-col p:first-child {
  color: var(--ion-color-gray);
  font-size: 8px;
  margin-bottom: 8px;
  font-weight: bold;
}

.history-item ion-col p:last-child {
  color: var(--ion-color-medium);
  font-size: 9px;
  margin-top: 2px;
  margin-bottom: 10px;
}

.history-item ion-col:last-child p {
  text-align: right;
  font-size: 15px;
  margin-top: 25px;
  font-weight: 500;
  color: var(--ion-color-primary);
}

.history-item ion-col:last-child p.redeem {
  /* text-align: right; */
  font-size: 15px;
  /* margin-top: 25px; */
  font-weight: 500;
  color: var(--ion-color-danger);
}

.order-button-wrapper {
  float: right;
}

.order-button-wrapper .order-button {
  padding: 3px 8px 2px 8px;
  display: inline-flex;
  justify-content: center;
  font-size: 9px;
}

.order-button-wrapper .order-button ion-icon {
  font-size: 10px;
}

.web ion-content.route-history {
  --background: transparent none;
  --ion-color-base: transparent;
}

.web .history-item {
  cursor: pointer;
}

.web #main > ion-content.route-history > .no-padding {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* top: 100px;
	bottom: 100px;
	width: 400px;
	margin-left: -200px; */
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  /* min-height: 450px; */
  width: var(--okx-bsmodal-width-lg);
  height: 75%;
}


@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-history > .no-padding {
    height: 65%;
  }
}
