.promo-bar,
.promo-bar-scrim {
  position: absolute;
  position: fixed;
  z-index: 5;
}

/* .promo-bar-scrim {
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.000000001);
} */

.promo-bar {
  left: 10%;
  right: 10%;
  top: 20%;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  background: var(--ion-color-primary) url(../../assets/images/info-white.svg) no-repeat 5px 50%;
  background-size: 30px 30px;
}

.promo-bar.warning {
  background-image: url(../../assets/images/warning-white.svg);
}

.promo-bar.error {
  background-image: url(../../assets/images/error-white.svg);
}

.promo-bar-type {
  font-weight: bold;
}

.promo-bar-type,
.promo-bar-body {
  color: var(--ion-color-primary-contrast);
}
