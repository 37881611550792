.price-item ion-label.sc-ion-label-ios-h {
  font-weight: bold;
  text-transform: initial;
  margin: 0;
}

/* .border-left {
  border-left: 1px black solid;
  padding-left: 10px;
  width: fit-content;
} */

.border-right {
  border-right: 1px black solid;
  width: fit-content;
  padding-right: 20px;
  margin-right: 10px;
}

.split-number-input {
  width: 30%;
  text-align: center;
}

.split-num-wrapper {
  display: flex;
  border: 1px solid var(--ion-color-primary);
  padding-left: 0 10px;
  border-radius: 10px;
}

.split-another-wrapper {
  display: flex;
  border: black 1px solid;
  width: 50%;
  padding-left: 20px;
  padding-right: 10px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

.split-pay-wrapper {
  margin-top: 40px;
}

.select-portion {
  border: 1px solid;
  border-radius: 10px;
}

.split-table {
  /* margin: 10px 25px; */
}

.route-split-pay ion-row {
  border-bottom: 1px solid var(--ion-color-primary);
}
