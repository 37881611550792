.order-categories {
  flex: 0 0 auto;
  padding: 0;
}

.order-categories h2 {
  font-family: var(--ion-second-font);
  padding: 0 25px;
}

.order-categories-inner {
  display: flex;
  flex-flow: row;
  width: 100%;
  overflow-y: auto;
}

div.order-categories-inner > ion-button:first-child {
  padding-left: 25px;
}

.order-content {
  flex: 1 1 auto;
  padding: 0 25px;
  overflow: hidden;
}

.category-button {
  font-size: var(--okx-small-text-font-size);
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-gray);
  --color: var(--ion-color-black);
  font-weight: normal;
  --border-radius: 14px;
}

.category-button.active {
  --background: var(--ion-color-primary);
  --border-width: 0px;
  --border-style: unset;
  --border-color: transparent;
  --color: #fff;
  --background-activated: var(--ion-color-primary);
}

.order-list-items {
  overflow-y: auto;
  height: 100vh;
  /* padding-bottom: 70px; */
}

.order-list-items > .list-md {
  padding-bottom: 0;
}

.order-list-items ion-item {
  --min-height: 20px;
  --inner-padding-end: 0;
}

.order-list-item {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}
.order-list-item > div {
  flex: 0 1 auto;
  margin-left: 10px;
}
.order-list-item > div:first-child {
  margin-left: 0;
  flex: 0 0 50px;
  display: flex;
}
.order-list-item > div:nth-child(2) {
  flex: 1 1 auto;
}
.order-list-item > div:nth-child(3) {
  text-align: right;
}
.item-content {
  font-size: 0.8em;
}
.item-content > h5 {
  margin: 0;
  font-family: var(--ion-default-font-medium);
  font-size: 13px;
  font-weight: bold;
  color: var(--ion-color-primary);
  text-transform: capitalize;
}
.item-content p {
  margin: 0;
}
.item-image {
  border-radius: 10px;
  background: none transparent no-repeat 50% 50%;
  background-size: cover;
  width: 50px;
  padding-top: 100%;
}
.item-price > span:first-child {
  color: var(--ion-color-secondary);
  font-family: var(--ion-default-font-medium);
  font-size: 13px;
  font-weight: bold;
}
.item-price > span.original-price {
  text-decoration: line-through;
  font-size: 0.9em;
}
/*
.order-list-item:last-of-type {
	margin-bottom: 0;
}

.order-list-item > div:first-of-type {
	flex: 1;
	display: flex;
	align-items: center;
}

.order-list-item > div:first-of-type > img {
	border-radius: 10px;
	margin-right: 10px;
}

.order-list-item > div:first-of-type > div {
	display: flex;
	flex-flow: column;
}

.order-list-item > div:first-of-type > div > p {
	color: var(--ion-color-medium);
	font-size: 11px;
	margin:0;
}

.order-list-item > div:first-of-type > div > ion-label {
	font-size: 16px;
}

.order-list-item > p:last-of-type {
	color: var(--ion-color-workshop);
}
*/
.order-list-header {
  padding: 25px;
  margin-bottom: 10px;
}

.order-list-header ion-label {
  font-size: 25px;
  font-weight: 600;
  text-transform: initial;
}

.order-sublist-header {
  padding-left: 0;
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  background-color: white;
  z-index: 2;
  transform: translate3d(0, 0, 0);
  --color: var(--ion-color-dark);
}
.order-sublist-header ion-label {
  font-family: var(--ion-second-font);
  font-size: 20px;
  font-weight: 700;
  color: var(--ion-color-secondary);
  text-transform: initial;
  margin-bottom: var(--okx-flex-spacer);
}

.order-intro-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: var(--ion-color-light-shade);
}

.order-intro-image-wrapper {
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-black);
  background: var(--ion-color-light-shade);
  width: 100%;
}

.order-intro-content {
  height: 250px;
  padding: 25px;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  background: var(--ion-color-medium-contrast);
}

.order-intro-content p {
  color: var(--ion-color-black);
  text-align: center;
  padding: 0 10px;
  font-size: 14px;
  margin: 25px 0;
}

.order-intro-modal > .modal-wrapper {
  height: 320px;
  width: 270px;
  border-radius: 15px;
}

.order-intro-modal > .modal-wrapper > .ion-page {
  min-width: 100%;
  padding: 15px 25px;
}

.order-intro-modal > .modal-wrapper > .ion-page ion-icon {
  position: absolute;
  right: 15px;
  font-size: 35px;
}

.order-intro-modal > .modal-wrapper > .ion-page h2 {
  font-size: 20px;
  margin-top: 4px;
}

.order-intro-modal > .modal-wrapper > .ion-page .order-intro-modal-inner {
  flex: 1 1 0%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

.location-select {
  --padding-start: 0;
  margin-top: 5px;
  border-bottom: 1px solid var(--ion-color-medium);
}

.popover-content ion-item ion-label {
  margin-left: 10px;
}

.pickup-time {
  margin-top: 5px;
  border-bottom: 1px solid var(--ion-color-medium);
}

.item-content {
  margin-right: auto;
}

.item-image {
  margin-left: auto;
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
}
.app {
  display: grid;
  grid-template-columns: 20% 80%;
}
.navbar {
  padding: 0.5rem;
}
.navbar .nav-link {
  padding: 0.5rem;
}
.header {
  height: 80px;
  overflow: auto;
  background: #aaa;
}
.container {
  height: 500px;
  background: #ddd;
}
.gap {
  height: 500px;
}
.gap.short {
  height: 250px;
}
.gap.tall {
  height: 1000px;
}
.container.relative {
  overflow-y: auto;
}

.view-order-button {
  position: fixed;
  bottom: 10px;
  text-align: center;
  z-index: 3;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
}

.view-order-button ion-button {
  height: 45px !important;
}

@media (max-width: 700px) {
  .app {
    grid-template-columns: unset;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  /* iOS */
  .order-list-items {
    height: 100vh;
  }
}

.mb-5 {
  font-size: 12px !important;
  margin-bottom: 5px !important;
  padding-top: 2px !important;
}

.custom-line-height {
  line-height: 15px;
}

.segment-holder2 {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}
