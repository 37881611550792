.validate-content {
  margin-top: 50px;
}

.link.link-add-items {
  max-height: 28px;
  background: var(--ion-color-primary);
  padding-left: 6px;
  padding-right: 12px;
  padding-bottom: 8px;
  border-radius: 30px;
}

.checkout .flex-min > ion-button {
  font-family: var(--ion-default-font-medium);
  font-weight: var(--okx-text-bold);
  text-transform: var(--okx-text-uppercase);
}

.allergen-modal > .modal-wrapper > .ion-page {
  min-width: 100%;
  overflow: auto;
}

.contact-number > ion-label > ion-text > div {
  font-size: 16px;
  font-weight: bold;
  font-family: var(--ion-default-font-medium);
  display: inline;
}

#main ion-content .route-order-summary ion-label.ion-text-wrap {
  font-family: var(--ion-default-font);
  font-family: var(--ion-second-font);
  font-weight: bold;
}

.web ion-content.route-order-summary {
  --background: transparent;
  --ion-color-base: transparent;
  background-size: cover;
  background-image: url(../../assets/images/Click&Collect.jpg);
}

.no-wrap {
  white-space: nowrap;
}

.web ion-content.route-order-summary .ion-padding > div ion-button {
  text-transform: none !important;
}
.bill-pay-service-charge-wrapper {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}
.bill-pay-service-charge-wrapper > div {
  display: flex;
}
.bill-pay-service-charge-wrapper ion-button {
  flex: 1;
}
.ion-color-secondary.bill-pay-service-charge-button.is-active {
  --ion-color-base: var(--ion-color-secondary) !important;
  --ion-color-contrast: var(--ion-color-white) !important;
}
ion-button.ion-color-secondary.bill-pay-service-charge-button {
  --ion-color-base: transparent !important;
  --ion-color-contrast: var(--ion-color-secondary) !important;
  --border-style: solid;
  --border-width: 1px;
  --border-color: var(--ion-color-secondary);
}
.route-bill-summary ion-grid {
  border-bottom: 1px solid var(--ion-color-primary);
  padding: 0;
}
.route-bill-summary ion-col {
  padding-left: 0;
  padding-right: 0;
}
