.web ion-content.route-delivery {
	--ion-color-base: transparent;
	--background: transparent;
}

.ios .delivery-radio-button {
	border: 1px solid;
    width: 20px;
    height: 20px;
    border-radius: 30px;
    padding-right: 0;
    margin-right: 10px;
}