.item-name {
    font-size: 14px;
    font-weight: 700;
    color: '#284734';
}

.item-name ion-item ion-label {
    --color: #284734 !important;
    font-size: 14px !important;
    font-weight: 700 !important;
}