ion-content div.dash-layout {
  background-image: url(../../assets/images/dashboard-03.jpg);
  background-repeat: no-repeat;
  background-position: 45% 100%;
  background-size: 120% auto;
}

ion-content div.dark-layout {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(../../assets/images/dashboard-03.jpg);
  background-repeat: no-repeat;
  background-position: 45% 100%;
  background-size: 120% auto;
}

ion-content div.dash-layout::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 0%;
  z-index: 0;
  background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

ion-content div.dash-layout::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 0%;
  z-index: 0;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.dash-card > ion-card-header > ion-card-title {
  margin-bottom: 10px;
}

.dash-card {
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 0;
  z-index: 1;
  --background: #fff;
  border-radius: var(--ion-card-radius);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-bottom: 25%;
  background-image: url(../../assets/images/rope-transparent.png);
  background-repeat: no-repeat;
  background-position: 50% 105%;
  background-size: 60% auto;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  margin-top: 0;
  margin-bottom: 0;
}

.dash-menu {
  padding: 0 64px;
}

.dash-menu .list-md {
  background: transparent;
}

.dash-menu ion-item {
  --color: var(--ion-color-primary);
  --background: trasparent;
  --background-hover: trasparent;
  --min-height: 32px;
  --border-color: var(--okx-color-border-dash);
}

.dash-card ion-card-title {
  font-size: var(--ion-font-size);
  font-weight: normal;
  text-transform: var(--okx-text-uppercase);
  letter-spacing: 0;
}

.dash-card ion-card-title + ion-text > .title {
  font-size: 30px;
  text-align: center;
  white-space: pre-wrap;
}

.dash-menu ion-label {
  text-align: center;
}

/* .dash-menu > ion-list > ion-item.item > ion-label.sc-ion-label-ios-h {
	font-family: var(--ion-default-font-medium) !important;
	font-weight: normal !important;
	text-transform: initial !important;
} */

.dash-menu > ion-list > ion-item > ion-label.sc-ion-label-ios-h {
  font-weight: normal;
  text-transform: initial;
  margin: 0;
}

.drawer-menu .nav-icon,
.drawer-menu .additional-routes {
  font-size: 24px;
  text-transform: capitalize;
}
