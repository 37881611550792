.order-categories {
  flex: 0 0 auto;
  padding: 0;
}

.order-categories h2 {
  padding: 0 25px;
}

.order-categories-inner {
  display: flex;
  flex-flow: row;
  width: 100%;
  overflow-y: auto;
}

.order-content {
  flex: 1 1 auto;
  padding: 0 25px;
  overflow: hidden;
}

.category-button {
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-gray);
  --color: var(--ion-color-black);
  font-weight: normal;
  --border-radius: 14px;
  text-transform: uppercase;
}

.category-button.active {
  --background: var(--ion-color-primary);
  --border-width: 0px;
  --border-style: unset;
  --border-color: transparent;
  --color: #fff;
  --background-activated: var(--ion-color-secondary);
}

.web .order-list-items {
  overflow-y: auto;
  height: 100%;
  padding-right: 30px;
  /* padding-bottom: 70px; */
}

.item-content {
  font-size: 0.8em;
}
.item-content > h5 {
  margin: 0;
  font-size: 13px;
  font-weight: bold;
  color: var(--ion-color-dark);
}
.item-content p {
  margin: 0;
}
.order-list-header {
  padding: 25px;
  margin-bottom: 10px;
}

.order-list-header ion-label {
  font-size: 25px;
  font-weight: 600;
  text-transform: initial;
}

.order-sublist-header {
  padding-left: 0;
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  background-color: white;
  z-index: 999;
  --color: var(--ion-color-dark);
}
.order-sublist-header ion-label {
  font-size: 15px;
  font-weight: 700;
  text-transform: initial;
}

.order-intro-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: var(--ion-color-light-shade);
}

.order-intro-image-wrapper {
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-black);
  background: var(--ion-color-light-shade);
  width: 100%;
}

.order-intro-content {
  height: 250px;
  padding: 25px;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  background: var(--ion-color-medium-contrast);
}

.order-intro-content p {
  color: var(--ion-color-black);
  text-align: center;
  padding: 0 10px;
  font-size: 14px;
  margin: 25px 0;
}

.order-intro-modal > .modal-wrapper {
  height: 320px;
  width: 270px;
  border-radius: 15px;
}

/* .web .order-intro-modal > .modal-wrapper {

} */

.order-intro-modal > .modal-wrapper > .ion-page {
  min-width: 100%;
  /* padding: 15px 25px; */
}

.order-intro-modal > .modal-wrapper > .ion-page ion-icon {
  position: absolute;
  right: 15px;
  font-size: 35px;
  cursor: pointer;
}

.order-intro-modal > .modal-wrapper > .ion-page h2 {
  font-size: 20px;
  margin-top: 4px;
}

.order-intro-modal > .modal-wrapper > .ion-page .order-intro-modal-inner {
  flex: 1 1 0%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

.order-intro-modal
  > .modal-wrapper
  > .ion-page
  > .voucher-info-wrapper
  > .order-intro-modal-content {
  padding: 0 20px;
}

.location-select {
  --padding-start: 0;
  margin-top: 5px;
  border-bottom: 1px solid var(--ion-color-medium);
}

.popover-content ion-item ion-label {
  margin-left: 10px;
}

.pickup-time {
  margin-top: 5px;
  border-bottom: 1px solid var(--ion-color-medium);
}

.item-content {
  margin-right: auto;
}

.app {
  display: grid;
  grid-template-columns: 20% 80%;
}
.navbar {
  padding: 0.5rem;
}
.navbar .nav-link {
  padding: 0.5rem;
}
.header {
  height: 80px;
  overflow: auto;
  background: #aaa;
}
.container {
  height: 500px;
  background: #ddd;
}
.gap {
  height: 500px;
}
.gap.short {
  height: 250px;
}
.gap.tall {
  height: 1000px;
}
.container.relative {
  overflow-y: auto;
}

.view-order-button {
  position: fixed;
  bottom: 10px;
  font-family: var(--ion-font-family);
  text-align: center;
  z-index: 1;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 700px) {
  .app {
    grid-template-columns: unset;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  /* iOS */
  .order-list-items {
    height: 100vh;
  }
}

/* new */

.web .order-modal-bigger .modal-wrapper.sc-ion-modal-ios,
.web .order-modal-bigger .modal-shadow.sc-ion-modal-ios,
.web
  .order-modal-bigger
  .modal-wrapper.sc-ion-modal-md
  .web
  .order-modal-bigger
  .modal-shadow.sc-ion-modal-md {
  --width: 680px;
}

.web ion-modal.modal-classic.order-modal-bigger {
  --width: 680px;
}

.web .order-list {
  position: absolute;
  left: 0;
  top: 0;
  right: var(--okx-sidebar-width);
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.web .order-list.order-list-full {
  right: 0;
}

.web .order-summary-sidebar,
.web .order-summary-sidebar::before,
.web .order-summary-sidebar::after {
  left: calc(100% - var(--okx-sidebar-width));
}

.web .order-summary-sidebar .dynamic-header {
  display: none;
}

.web .order-summary-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.web .order-summary-sidebar .add-items-row {
  display: none;
}

.web .order-summary-sidebar::before,
.web .order-summary-sidebar::after {
  content: '';
  position: fixed;
  top: -200px;
  bottom: 0;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 3;
}

.web .order-summary-sidebar::before {
  width: 20px;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .order-summary-sidebar::after {
  width: 50px;
  background-color: #fff;
}

.web .order-summary-sidebar .absolute-content {
  top: 0;
  padding-top: 0;
  z-index: 5;
}

.web .order-summary-sidebar .title {
  font-size: var(--okx-subtitle-font-size);
}

.web .order-summary-sidebar .checkout div.righted:first-of-type {
  visibility: hidden;
}

.web .static.route-order {
  background-color: #fff;
}

.web .route-order .title {
  padding: 0 0 5px 0;
}

.web .order-categories {
  padding: 10px 10px 0 50px;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  background-color: var(--ion-color-white);
  z-index: 2;
}

.web .order-categories h2 {
  padding: 0;
  font-size: var(--okx-title-font-size);
}

.web .order-categories-inner {
  flex-wrap: wrap;
  column-gap: 10px;
}

.web div.order-categories-inner > ion-button:first-child {
  padding-left: 0px;
}

/* .web .order-categories-inner ion-button {
	text-transform: none;
} */

.web .order-content {
  padding: 0 10px 20px 50px;
  height: 100vh;
}

/* grid */

.web .order-list-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: stretch;
  align-items: stretch;
  row-gap: 15px;
  column-gap: 15px;
  padding-right: 10px;
}

.web .order-list-grid .order-list-item {
  display: block;
  width: auto;
  margin-bottom: 0;
  min-width: 0;
  background-color: #fff;
  padding: 8px;
  padding-bottom: 2px;
  /*border: 1px solid #eee;*/
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.web .order-list-grid .order-list-item .item-image {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  min-width: 0;
  min-height: 0;
  margin: 0;
  display: block;
  height: auto;
  border-radius: 0;
}

.web .order-list-grid .order-list-item .item-image .item-image-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.web .order-list-grid .order-list-item .item-bar {
  margin: 5px 0 0 0;
  display: flex;
  justify-content: space-between;
}

.web .item-bar .item-name,
.web .item-bar .item-price {
  font-size: var(--okx-small-text-font-size);
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.web .route-order .item-bar .item-price {
  text-align: right;
  align-self: center;
  flex: 0 1 50%;
}

.web .route-order .item-bar .item-name.ellipsis {
  font-weight: bold;
  white-space: normal;
  text-overflow: unset;
}

.web .item-bar .item-price {
  text-align: right;
}

.web .item-bar .item-price > span:first-child {
  color: var(--ion-color-secondary);
  font-size: inherit;
  font-weight: bold;
}

.web .item-bar .item-price > span.original-price {
  text-decoration: line-through;
  font-size: calc(var(--okx-small-text-font-size) - 1px);
}

.web ion-modal.modal-classic {
  --width: 500px;
  --height: 80%;
  min-height: 400px;
}

.web ion-modal.modal-classic .modal-classic-wrapper,
.web ion-modal.modal-classic .modal-classic-content {
  height: 100%;
}

.web ion-modal.modal-classic .modal-classic-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.web ion-modal.modal-classic .item-details-card {
  overflow-y: hidden;
  display: grid;
  grid-template-columns: 35% 65%;
  grid-template-rows: min-content auto min-content;
  border-radius: 0;
  height: 100%;
  overflow-x: hidden;
}

.web ion-modal.modal-classic .item-details-card .item-details-card-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 20px;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 1;
}

.web ion-modal.modal-classic .item-details-card .item-details-image {
  position: relative;
  grid-area: 1 / 1 / 3 / 2;
  height: 100%;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 1;
}

.web ion-modal.modal-classic .item-details-card .item-details-image .item-details-image-bg {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 15%;
  margin-top: 10px;
}

.web ion-modal.modal-classic .item-details-actions {
  position: sticky;
  position: -webkit-sticky;
  margin: 0 auto;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding-bottom: 12px;
  grid-area: 3 / 1 / -1 / -1;
  box-shadow: none;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 1;
}

.web ion-modal.modal-classic.allergen-modal,
.web ion-modal.modal-classic.apply-points-modal {
  --width: var(--okx-sidebar-width);
  --height: 100%;
  --border-radius: 0;
  justify-content: flex-end;
}

.web ion-modal.modal-classic.allergen-modal .modal-classic-wrapper,
.web ion-modal.modal-classic.apply-points-modal .modal-classic-wrapper {
  left: calc(100% - var(--okx-sidebar-width));
}

.web ion-modal.modal-classic.allergen-modal .absolute-content,
.web ion-modal.modal-classic.apply-points-modal .absolute-content {
  padding-top: 54px;
}

/* .web ion-modal.modal-classic.allergen-modal .rhino {
	background: transparent url(../../assets/images/rhino.svg) no-repeat 50% 100% / 180px auto;
} */
.web ion-modal.modal-classic.apply-points-modal .incrementer-decrease,
.web ion-modal.modal-classic.apply-points-modal .incrementer-incease {
  --color: var(--ion-color-dark);
}

.web .order-sublist-holder {
  margin-bottom: 30px;
  grid-column: 1 / -1;
}

.web .order-sublist-holder:last-of-type {
  margin-bottom: 0px;
}

.web ion-modal.modal-classic.apply-points-modal .incrementer {
  display: grid;
  grid-template-columns: 38px 1fr 38px;
  grid-column-gap: 5px;
  padding: 0;
}

.web ion-modal.modal-classic.allergen-modal .title.centered {
  text-align: left;
}

.web ion-modal.modal-classic.allergen-modal .sectiontitle {
  margin: 0;
  font-weight: bold;
}

.web .order-summary-sidebar ion-content {
  z-index: 5;
}