.okx-validate-input-wrapper {
  display: flex;
  width: 100%;
}

.okx-validate-input-wrapper .okx-validate-input-holder {
  flex: 1 1 auto;
  /* display: grid;
    grid-template-columns: 1fr 1fr; */
  padding-bottom: 5px;
}
/* .okx-validate-input-wrapper .okx-validate-input-holder.okx-validate-input-holder--date {
    width: 50vw;
    grid-template-columns: 50% 50%;
} */

.okx-validate-input-holder.okx-validate-input-holder--date label {
  position: relative;
  font-size: 11px !important;
}

/* .okx-validate-input-holder.okx-validate-input-holder--date label::after {
    content: ':';
    position: absolute;
    font-family: var(--ion-default-font-medium);
    font-weight: bold;
    right: -9px;
    font-size: 13px;
    line-height: 13px;
} */

.okx-validate-input-holder.okx-validate-input-holder--email ion-label {
  align-self: center;
  height: 17px;
}

/* .okx-validate-input-holder ion-input::before, .okx-validate-input-holder input::before {
    content: ':';
    margin-right: 5px;
} */

.okx-validate-input-holder.okx-validate-input-holder--email input {
  text-overflow: ellipsis;
}

.okx-validate-input-holder.okx-validate-input-holder--date label,
.okx-validate-input-holder.okx-validate-input-holder--date input {
  align-self: center;
  color: var(--ion-color-primary);
  font-size: var(--ion-font-size);
  font-weight: 300;
}

.okx-validate-input-holder.okx-validate-input-holder--date input {
  font-weight: bold;
  /* margin-left: 13px; */
}

.okx-validate-input-holder input {
  text-overflow: ellipsis;
}

.okx-validate-input-wrapper .okx-validate-input-holder ion-datetime {
  --padding-start: 0;
  --padding-top: 15px;
}
.okx-validate-input-wrapper .okx-validate-input-holder p {
  font-size: 11px;
  font-weight: 500;
  /* margin: 21px 0 0 0; */
  display: flex;
  padding-left: 2px;
  color: inherit;
  white-space: nowrap;
}

.okx-validate-input-wrapper .okx-validate-input-holder ion-label,
.okx-validate-input-wrapper .okx-validate-input-holder .date-picker-wrapper > label {
  align-self: center;
  font-family: var(--ion-font-family);
  font-weight: 100;
  text-transform: initial;
  line-height: 15px;
}

.okx-validate-input-wrapper .okx-validate-input-holder .date-picker-wrapper > input {
  --color: var(--ion-color-primary);
  color: var(--color);
  font-weight: var(--okx-text-bold);
  --placeholder-opacity: 1;
}

.okx-validate-input-wrapper ion-button {
  flex: 1 0 auto;
  min-width: 32px;
  padding-bottom: 10px;
}

.okx-validate-input-holder .data-picker-input {
  width: 100%;
}

.date-picker-wrapper.modal .data-picker-input {
  padding-top: 15px;
}

.date-picker-wrapper.modal ion-label {
  font-size: 8px;
}

.date-picker-modal-label {
  font-size: 9px;
  color: var(--ion-color-dark);
  font-weight: bold;
  margin-left: 2px;
  transition: 0.1s ease-in;
}

.modal-wrapper.sc-ion-modal-ios > .top-medium > ion-button.validation {
  text-transform: uppercase;
}
