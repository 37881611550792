.route-cards .ion-padding .absolute-content .link {
  font-size: 11px !important;
  /* font-weight: bold; */
  text-transform: initial;
}

.web ion-content.route-cards {
  --background: transparent none;
  --ion-color-base: transparent;
}

.web .route-cards .ion-padding {
  position: absolute;
  left: 50%;
  top: 30px;
  bottom: 50px;
  width: 400px;
  margin-left: -200px;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .route-cards .ion-padding .absolute-content {
  padding: 32px;
}

.web .route-cards .ion-padding .absolute-content .scrollable-y/*,
.web .route-cards .ion-padding .absolute-content .flex-min*/ {
  padding: 0 32px;
}

.web .route-cards .ion-padding ion-button:last-child {
  margin-bottom: 0;
}
