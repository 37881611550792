.validate-button-wrapper {
  height: 45px;
}

.validate-button-wrapper ion-row {
  /* width: 165px; */
  width: 160px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  border-radius: 20px;
  border: 1px solid var(--ion-color-gray);
  margin-bottom: 25px;
  padding: 5px 0;
  color: var(--ion-color-gray);
  cursor: pointer;
  column-gap: 5px;
}

.validate-button-wrapper ion-row ion-col {
  flex-grow: 0;
  padding: 0;
}

.validate-button-wrapper ion-row ion-col:nth-of-type(1) ion-text span {
  white-space: nowrap;
}

.validate-button-wrapper.validate-button-wrapper--gridy ion-row {
  display: grid;
  grid-template-columns: 30px 1fr;
  padding: 0 5px;
}

.validate-button-wrapper ion-row ion-col div ion-icon {
  font-size: 19px;
  color: var(--ion-color-gray);
}

.validate-button-wrapper ion-row ion-col.verified-right {
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  justify-self: flex-start;
  flex-grow: 3;
}
