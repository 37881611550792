.reset-password-background {
  /* background: url(../../assets/images/HolyCow-main-background.png) transparent no-repeat 50% 100%; */
  background-size: 100% auto;
}

.web ion-content.route-reset-password {
  --background: transparent;
  --ion-color-base: transparent;
}

.web #main > ion-content.route-reset-password > .ion-padding {
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  padding: 48px 32px 5px;
  height: 55%;
}

#main > ion-content.route-reset-password > .ion-padding ion-list > ion-item {
  --border-color: rgba(var(--ion-color-light-rgb), 1);
}

.web .route-reset-password .ion-padding .absolute-content {
  padding: 48px 32px 40px;
}

@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-reset-password > .ion-padding {
    height: 45%;
  }
}
