ion-button.link.link-centered {
  margin: 0 auto;
}
.absolute-content--checkout > .flex-min > ion-button.ion-color-secondary {
  --ion-color-contrast: var(--ion-color-white, #fff) !important;
}

.web ion-content.route-checkout {
  --background: transparent;
  --ion-color-base: transparent;
}

.web .route-checkout .ion-padding {
  position: absolute;
  left: 50%;
  top: 30px;
  bottom: 50px;
  width: var(--okx-bsmodal-width-lg);
  margin-left: -200px;
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.web .route-checkout .ion-padding .absolute-content {
  padding: 32px 32px 16px;
}

.web .route-checkout .ion-padding .absolute-content .scrollable-y/*,
.web .route-checkout .ion-padding .absolute-content .flex-min*/ {
  padding: 0 32px;
}

.paymentRequestButton {
  margin-top: 20px;
  padding: 0 2px;
}

.paymentRequestButtonCardAdd {
  margin-bottom: 20px;
  padding: 0 2px;
}

.paymentRequestButton iframe {
  border-radius: 20px;
  height: 40px;
}
