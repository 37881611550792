.menu-enabled .list-md {
  padding-top: 0;
  padding-bottom: 0;
}

.drawer-menu .title-default {
  text-transform: uppercase;
  font-weight: bold;
  padding-inline-start: 0;
}

.drawer-menu ion-header,
.drawer-menu ion-content,
.drawer-menu ion-footer {
  --padding-start: var(--okx-drawer-padding);
  --padding-end: var(--okx-drawer-padding);
  padding-left: var(--okx-drawer-padding);
  padding-right: var(--okx-drawer-padding);
}

.drawer-menu ion-header,
.drawer-menu ion-footer {
  background-color: var(--ion-color-primary);
}

.drawer-menu ion-header {
  padding-top: 10px;
  padding-bottom: 20px;
}

.drawer-menu ion-footer {
  background-image: none;
  padding-top: 5px;
  padding-bottom: 20px;
}

.drawer-menu ion-footer::before {
  background-image: none;
}

.drawer-menu .nav-logo {
  width: 100%;
  height: 52px;
  background: url(../../assets/images/logo-nav.svg) transparent no-repeat;
  background-size: contain;
}

.drawer-menu .nav-item {
  --padding-start: 0;
  --min-height: 24px;
  font-weight: bold;
}

.drawer-menu .nav-item.small-text {
  font-weight: normal;
}

.drawer-menu .nav-icon,
.drawer-menu .nav-label {
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 100;
}

.drawer-menu .nav-label {
  font-family: var(--ion-default-font-medium);
  font-weight: var(--okx-text-bold);
  text-transform: var(--okx-text-uppercase);
  margin-left: 2px;
}

.terms-items .nav-item .nav-label {
  font-weight: normal;
}

.drawer-menu ion-header ion-toolbar:last-child {
  --border-width: 0 0 0;
}

.drawer-menu ion-icon {
  fill: #fff;
}

.nav-item.login-item > ion-icon {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
}

ion-split-pane {
  --side-max-width: var(--okx-drawer-width);
  --side-min-width: var(--okx-drawer-width);
  --side-width: var(--okx-drawer-width);
  --border: none;
}

ion-menu {
  --background: var(--ion-color-primary) /* url(../../assets/images/app_bg_green.jpg) */ no-repeat;
}

.web ion-menu {
  --max-width: var(--okx-drawer-width);
  --background: var(--ion-color-primary);
}

.logo-5l {
  position: absolute;
  right: 16px;
  bottom: 8px;
}

.logo-5l > div {
  width: 100px;
  padding-top: 45%;
  background: url(../../assets/images/5loyalty_logo.svg) transparent no-repeat 50% 50%;
  background-size: 100% auto;
}

.terms-list ion-icon {
  font-size: 22px;
}

.terms-list .nav-label {
  color: var(--ion-color-gray);
  font-size: var(--ion-font-size);
}

ion-footer > ion-label[hidden] {
  display: block !important;
}

.collapse-drawer-icon,
ion-content.log-status {
  display: none;
}

/* .web ion-footer > ion-label[hidden] {
	display: none !important;
} */

.web .drawer-menu ion-header ion-toolbar {
  position: relative;
}

.web .drawer-menu .nav-logo {
  width: 90%;
  cursor: pointer;
  background-size: auto 65%;
  background-position: 0% 50%;
  fill: #fff;
}

.web .drawer-menu .nav-icon {
  font-size: 20px;
}

.web .small-drawer-header {
  padding: 15px 0 0 0;
}

.web .small-drawer-toolbar {
  --background: transparent;
}

.web .small-drawer-header ion-buttons {
  position: absolute;
  top: 1px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.web .small-drawer-header ion-button {
  --padding-top: 12px;
  --padding-end: 12px;
  --padding-bottom: 12px;
  --padding-start: 12px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 48px;
  height: 48px;
  background: var(--ion-color-white);
}

.web .small-drawer-header ion-button ion-icon {
  font-size: 2rem;
  /* color: var(--okx-color-black); */
  color: var(--ion-color-secondary);
}

.web .collapse-drawer-icon {
  display: block;
  position: absolute;
  color: #fff;
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
  cursor: pointer;
  font-size: 25px;
}

.web ion-content.log-status {
  display: block;
  max-height: 115px;
}

.web .log-status-wrap {
  background: var(--ion-color-white);
  border-radius: var(--okx-box-radius);
}

.web .log-status-wrap ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
}

.web .log-status-wrap ion-item:first-of-type {
  --background: var(--ion-color-white);
  --border-radius: var(--okx-box-radius);
  backface-visibility: hidden;
  z-index: 2;
}

.web .log-status-wrap ion-item:last-of-type {
  border-bottom-left-radius: var(--okx-box-radius);
  border-bottom-right-radius: var(--okx-box-radius);
  --min-height: 35px;
}

.web .log-status-wrap ion-item.logout-box {
  border-top: 1px solid var(--ion-item-border-color);
}

.web .log-status-content {
  width: 100%;
}

.web .log-status-content > ion-text .subtitle {
  margin: 0;
  color: var(--ion-color-primary);
  font-size: var(--ion-font-size);
  font-weight: 500;
}

.web .log-status-content > ion-text .small-text {
  color: var(--ion-color-gray);
  display: block;
  margin-top: -2px;
}

.web .log-status-wrap ion-icon {
  color: var(--okx-color-black);
}

.web .logout-box > ion-label,
.web .logout-box > ion-label > ion-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.web .logout-box > ion-label > ion-icon {
  font-size: 17px;
  margin-right: 5px;
}

.web .logout-box > ion-label > ion-text .normal-text {
  font-size: 15px;
  margin-left: 5px;
}

.web .logout-box {
  margin-top: -40px;
  transition: all 0.3s ease;
}

.web .logout-box.visible {
  margin-top: 0;
}

.web .drawer-menu ion-content:first-of-type {
  --padding-start: 0px;
}

.web .drawer-menu ion-content:first-of-type ion-item {
  --padding-start: var(--okx-drawer-padding);
}

.web .drawer-menu ion-content:first-of-type ion-item.okx-nav-selected > ion-icon {
  --marginPlusPaddingOfSelItem: calc(6px + 6px);
  background: rgba(var(--ion-color-tertiary-rgb), 0.8);
  border-radius: 50%;
  padding: 6px;
  /* margin: -6px 26px -6px -6px; */
  margin: -6px var(--marginPlusPaddingOfSelItem) -6px -6px;
}

.web .drawer-menu .nav-item {
  --min-height: 33px;
}

.web .drawer-menu .nav-icon,
.drawer-menu .nav-label {
  margin-top: 5px;
  margin-bottom: 5px;
}

.web .drawer-menu ion-header {
  height: 90px;
}

.web .drawer-menu .nav-item.small-text > .nav-icon,
.drawer-menu .nav-item.small-text > .nav-label {
  font-size: 13px;
  font-family: var(--ion-default-font);
  font-weight: normal;
  text-transform: initial;
  margin-top: 0;
  margin-bottom: 0;
}

.web .drawer-menu .nav-item.small-text > .nav-icon,
.drawer-menu .nav-item.small-text {
  --min-height: 26px;
}

.web .drawer-menu ion-content:first-of-type ion-item.small-text.okx-nav-selected > ion-icon {
  padding: 2px;
  margin: -2px 30px -2px -2px;
}

@media screen and (min-width: 992px) {
  .web .collapse-drawer-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
    cursor: pointer;
    font-size: 25px;
  }
}

@media screen and (min-width: 768px) {
  .web .collapse-drawer-icon {
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
    cursor: pointer;
    font-size: 25px;
  }
}

@media screen and (min-width: 1024px) {
  .web .collapse-drawer-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
    cursor: pointer;
    font-size: 25px;
  }
}
