ion-grid.no-padding.social ion-col {
  padding-top: 10px;
  padding-bottom: 10px;
}

ion-grid.social ion-col:nth-child(2n-1) {
  padding-right: 10px;
}

ion-grid.social ion-col:nth-child(2n) {
  padding-left: 10px;
}

.square {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.square:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.content {
  position: absolute;
  display: flex;
  flex-flow: column;
  background-color: var(--ion-color-light);
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.soc-icon {
  display: flex;
  border-radius: 50%;
  width: 30%;
  height: 30%;
  align-items: center;
  justify-content: center;
  font-size: 40px;
}

.soc-label {
  display: block;
  padding: 8px 0 0 8px;
}

.web .route-social.ion-color-white {
  --background: transparent none;
  --ion-color-base: transparent;
}

.web #main > ion-content.route-social > .ion-padding {
  position: absolute;
  width: var(--okx-bsmodal-width-lg);
  background-color: #fff;
  border-radius: var(--okx-box-radius);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
  padding: 64px;
  min-height: 70%;
  height: 80%;
  overflow-y: auto;
}

.web .square {
  cursor: pointer;
}

.web .route-social .ion-padding .heading-wrapper,
.web .route-social .ion-padding .frm-wrapper,
.web .route-social .ion-padding .action-wrapper {
  padding: 0 32px;
}

.web .route-social .ion-padding .frm-wrapper {
  position: absolute;
  left: 32px;
  right: 32px;
  top: 120px;
  bottom: 64px;
  overflow-y: auto;
  --ion-color-success: var(--ion-color-primary);
}

@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-social > .ion-padding {
    min-height: 70%;
  }
}
