.validate-modal {
  max-height: 72vh;
  width: 80vw;
  border-radius: 15px;
  text-align: center;
}

.validate-modal > .ion-page {
  min-width: 100%;
  padding: 15px 25px;
  overflow: auto;
  background: var(--ion-color-background);
  display: block;
}

.validate-modal > .ion-page h2 {
  font-size: 20px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.validate-modal > .ion-page ion-icon {
  position: absolute;
  right: 0;
  font-size: 15px;
  z-index: 5;
}

.validate-modal ion-item {
  --min-height: 20px;
  --inner-padding-end: 0;
}

.modal-content {
  margin-top: 20px;
}

.validate-modal .ion-page .inline-input > ion-text,
.validate-modal .ion-page .inline-input > label {
  flex: 0 0 40%;
}

.validate-modal .ion-page .inline-input > ion-input[name='email'] {
  text-overflow: ellipsis;
}

.web .validate-modal .ion-page {
  padding-top: 60px;
}

.web .validate-modal {
  width: 100%;
  height: 100%;
  max-height: 100%;
  --width: var(--okx-bsmodal-width-md);
  --border-radius: 15px;
}

.web .validate-modal .validate-modal-closer {
  display: inline-block;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.web .validate-modal .validate-modal-closer ion-icon {
  font-size: 20px;
  right: 0;
}

.validate-modal .validate-modal-closer ion-icon {
  right: 25px;
}

.web .validate-modal .validate-modal-header > br {
  display: none;
}

.web .validate-modal .validate-modal-header {
  text-align: left;
}

.web .validate-modal .validate-modal-header ion-text .title {
  font-weight: bold;
}

.web .validate-modal .validate-modal-header ion-text .small-text {
  color: var(--ion-color-gray);
}

.web .validate-modal .modal-classic-closer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 20px;
}

.validate-modal ion-content{
  background-image: none !important;
}