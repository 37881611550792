/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/poppins/Poppins-Medium.otf');
}

@font-face {
  font-family: 'Poppins-Light';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/poppins/Poppins-Light.otf');
}

@font-face {
  font-family: 'Poppins-Black';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/poppins/Poppins-Black.otf');
}
/* second-font */
@font-face {
  font-family: 'Crafter-Regular';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/crafter/Crafter-Regular.otf');
}

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #284734;
  --ion-color-primary-rgb: 40, 71, 52;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #233e2e;
  --ion-color-primary-tint: #3e5948;

  /** secondary **/
  --ion-color-secondary: #d78825;
  --ion-color-secondary-rgb: 215, 136, 37;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #bd7821;
  --ion-color-secondary-tint: #db943b;

  /** tertiary **/
  --ion-color-tertiary: #c1d5ae;
  --ion-color-tertiary-rgb: 193, 213, 174;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #aabb99;
  --ion-color-tertiary-tint: #c7d9b6;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #111;
  --ion-color-dark-tint: #444;

  /** gray **/
  --ion-color-gray: #9b9b9b;
  --ion-color-gray-rgb: 155, 155, 155;
  --ion-color-gray-contrast: #ffffff;
  --ion-color-gray-contrast-rgb: 255, 255, 255;
  --ion-color-gray-shade: #858585;
  --ion-color-gray-tint: #a1a1a1;

  /** greenlight **/
  --ion-color-greenlight: #c1d5ae;
  --ion-color-greenlight-rgb: 193, 213, 174;
  --ion-color-greenlight-contrast: #000000;
  --ion-color-greenlight-contrast-rgb: 0, 0, 0;
  --ion-color-greenlight-shade: #aabb99;
  --ion-color-greenlight-tint: #c7d9b6;

  /** light **/
  --ion-color-light: #ebebeb;
  --ion-color-light-rgb: 235, 235, 235;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d4d4d4;
  --ion-color-light-tint: #f1f1f1;

  /** superlight **/
  --ion-color-superlight: #f4f4f4;
  --ion-color-superlight-rgb: 244, 244, 244;
  --ion-color-superlight-contrast: #000000;
  --ion-color-superlight-contrast-rgb: 0, 0, 0;
  --ion-color-superlight-shade: #ebebeb;
  --ion-color-superlight-tint: #f9f9f9;

  /** white **/
  --ion-color-white: #fff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #222;
  --ion-color-white-contrast-rgb: 34, 34, 34;
  --ion-color-white-shade: #d7d8da;
  --ion-color-white-tint: #f5f6f9;

  /** black **/
  --ion-color-black: #111;
  --ion-color-black-rgb: 17, 17, 17;
  --ion-color-black-contrast: #eee;
  --ion-color-black-contrast-rgb: 238, 238, 238;
  --ion-color-black-shade: #000;
  --ion-color-black-tint: #555;

  /** form **/
  --ion-color-form-label: #b0abab;
  --ion-color-form-label-dark: #1e191a;
  --ion-color-note: #cbc6c6;

  /** custom values **/
  --ion-color-title2: #1073d0;
  --ion-color-box-holder: transparent;
  --ion-separator-color: #e6e6e6;
  --ion-feedback-label: #727272;
  --ion-top-small: 5px;
  --ion-top-medium: 30px;
  --ion-feedback-star: #9ba1a5;

  /** original theme file colors **/
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;

  --ion-padding: 32px;
  --ion-toolbar-min-height: 48px;

  --ion-card-radius: 20px;
  --ion-item-color: #999;
  --ion-item-background: transparent;
  --ion-text-color: var(--ion-color-primary);
  --ion-circle-color: rgb(232, 231, 233);

  /** fonts **/
  --ion-font-family: 'Poppins-Light';
  --ion-default-font: 'Poppins-Light';
  --ion-default-font-medium: 'Poppins-Medium';
  --ion-second-font: 'Crafter-Regular';
  --ion-font-size: 13px;

  /* okx vars */
  --okx-drawer-max-width: 280px;
  --okx-small-drawer-max-width: 90px;
  --okx-drawer-width: var(--okx-drawer-max-width);
  --okx-sidebar-width: var(--okx-drawer-max-width);
  --okx-flex-min: 5px;
  --okx-flex-spacer: 20px;
  --okx-big-label-font-size: 42px;
  --okx-big-label-color: var(--ion-color-primary);
  --okx-big-label-margin: 2px 0;
  --okx-big-label-padding: 0;
  --okx-title-font-size: 20px;
  --okx-title-color: var(--ion-color-primary);
  --okx-title-margin: 2px 0;
  --okx-title-padding: 0;
  --okx-subtitle-font-size: 16px;
  --okx-subtitle-color: var(--ion-color-secondary);
  --okx-subtitle-margin: 25px 0;
  --okx-subtitle-padding: 0;
  --okx-sectiontitle-font-size: 14px;
  --okx-sectiontitle-color: var(--ion-color-primary);
  --okx-sectiontitle-margin: 20px 0;
  --okx-sectiontitle-padding: 0;
  --okx-font-size: var(--ion-font-size);
  --okx-small-text-font-size: 11px;
  --okx-note-font-size: 12px;
  --okx-error-font-size: 11px;
  --okx-error-margin: 0 0 10px;
  --okx-border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
  );
  --okx-drawer-padding: 32px;
  --okx-box-radius: 10px;
  --okx-box-padding-left: 15px;
  --okx-box-padding-top: 15px;
  --okx-box-padding-right: 15px;
  --okx-box-padding-bottom: 15px;
  --okx-normal-text-color: var(--ion-text-color);
  --okx-light-text-color: var(--ion-color-gray);
  --okx-circle-color: var(--ion-circle-color);
  --okx-incrementer-button-width: 40px;
  --okx-loyalty-badge-width: 40px;
  --okx-loyalty-badge-inner-padding: 4px;
  --okx-loyalty-bar-height: 50px;
  --okx-color-border-dash: #2d2b2b;
  --okx-cancel-button-color: rgba(255, 255, 255, 0.2);
  --okx-text-uppercase: uppercase;
  --okx-text-bold: bold;
  --okx-color-border-dash: #2d2b2b;
  --okx-cancel-button-color: rgba(255, 255, 255, 0.2);
  --okx-scrollbar-handle-color: var(--ion-color-secondary);
  --okx-scrollbar-track-color: var(--ion-color-light);
  --okx-scrollbar-color: var(--okx-scrollbar-handle-color) var(--okx-scrollbar-track-color);
  --okx-color-black: #000;
}

.web {
  --ion-item-border-color: #000;
  --okx-bsmodal-top: 180px;
  --okx-bsmodal-width-lg: 500px;
  --okx-bsmodal-width-md: 420px;
  --okx-bsmodal-width-sm: 390px;
}
