ion-content.route-delivery-options {
  width: 100%;
  height: 100%;
  height: calc(100vh - 79px);
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-y: hidden;
}

ion-content.route-delivery-options::before {
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 85%;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  z-index: -1;
}

.delivery-options-card {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  top: 50%;
  left: 50%;
  min-width: 280px;
  min-height: 410px;
  padding-top: 32px;
  padding-bottom: 80px;
  margin: 0;
  z-index: 2;
  --background: #fff;
  border-radius: var(--ion-card-radius);
  transform: translate(-50%, -50%);
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25);
}

.delivery-options-card ion-card-title {
  font-size: 25px;
  font-weight: bold;
}

.delivery-options-menu {
  padding: 0 24px;
}

.delivery-options-menu ion-item {
  --ion-color-contrast: var(--ion-color-white) !important;
  --color: var(--ion-color-contrast);
  --background: var(--ion-color-secondary);
  --min-height: 32px;
  margin-bottom: 10px;
  border-radius: calc(var(--okx-small-radius) - 2px);
  cursor: pointer;
}

.delivery-options-menu ion-label {
  font-weight: normal;
  text-align: center;
}

.delivery-options-menu > ion-list > ion-item > ion-label {
  padding-top: 2px;
  font-weight: bold !important;
}

.web #main > ion-content.route-delivery-options {
  --background: transparent none;
  --ion-color-base: transparent;
  background-size: cover;
}

.web ion-content.route-delivery-options::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(8px);
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  z-index: -1;
}

.web .delivery-options-card {
  left: 50%;
  right: auto;
  width: var(--okx-bsmodal-width-sm);
}

.card-title {
  color: var(--ion-color-primary) !important;
  font-size: 22px !important;
  margin-bottom: 10px;
}

.delivery-options-item {
  border-radius: 30px !important;
  text-transform: uppercase;
}

.delivery-options-menu ion-label {
  margin-right: 10px !important;
  margin-left: 10px !important;
  font-family: var(--ion-default-font-medium) !important;
}
