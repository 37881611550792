.incrementer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.incrementer ion-button {
  --border-radius: 30px;
  width: 25px;
  height: 25px;
}

/* .incrementer > ion-button {
	margin: 0;
} */

.incrementer-decrease,
.incrementer-incease {
  --padding-start: 0;
  --padding-end: 0;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-light);
  --background: var(--ion-color-primary);
  --background-hover: var(--ion-color-light);
  --background-activated: var(--ion-color-light);
  --color: #fff;
}

.incrementer-quantity {
  flex: 1 1 auto;
  padding-left: var(--okx-incrementer-button-width);
  padding-right: var(--okx-incrementer-button-width);
}

.incrementer-quantity-solo {
  flex: 0 0 45px;
  text-align: center;
}

.incrementer-quantity-value {
  font-weight: bold;
}

.incrementer-note {
  font-size: var(--okx-small-text-font-size);
  color: var(--ion-color-gray);
}

.incrementer-decrease button {
  width: 25px !important;
  height: 25px !important;
}
