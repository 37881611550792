.feedback-label {
  font-size: 10px;
  color: var(--ion-feedback-label);
  color: var(--ion-color-black);
  margin: 5px;
  font-weight: bold;
}

.star-holder {
  padding: 10px 0px;
}

.star {
  font-size: 26px;
  color: var(--ion-feedback-star);
  margin-right: 15px;
}

.web .star {
  cursor: pointer;
}

.box-holder-top {
  margin-top: 20px;
}

.separator {
  height: 1px;
  /* background-color: var(--ion-separator-color); */
  background-color: rgba(var(--ion-color-primary-rgb), 0.4);
  margin: 0 5px;
}

.native-textarea.sc-ion-textarea-ios {
  padding-inline-start: 5px;
}

.commentService {
  height: 50px;
}

.commentTech {
  height: 160px;
}

.item.sc-ion-label-ios-h,
.item .sc-ion-label-md-h {
  margin-left: 2px;
}

.web ion-content.route-feedback {
  --background: transparent none;
  --ion-color-base: transparent;
  background-image: url(../../assets/images/dashboard-03.jpg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.web #main > ion-content.route-feedback > .ion-padding {
  width: 450px;
  height: 80%;
  overflow-y: auto;
}

.web .route-feedback .ion-padding .heading-wrapper,
.web .route-feedback .ion-padding .frm-wrapper,
.web .route-feedback .ion-padding .action-wrapper {
  padding: 0;
}

.web .route-feedback .ion-padding .frm-wrapper {
  position: absolute;
  left: 32px;
  right: 32px;
  top: 100px;
  bottom: 100px;
  overflow-y: auto;
  --ion-color-success: var(--ion-color-primary);
}

.web .route-feedback .ion-padding .action-wrapper {
  position: absolute;
  left: 32px;
  right: 32px;
  bottom: 0;
  padding-bottom: 32px;
}

.web .route-feedback .ion-padding ion-button:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 1441px) {
  .web #main > ion-content.route-feedback > .ion-padding {
    height: 65%;
  }
}
