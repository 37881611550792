ion-header.static {
  margin-bottom: -48px;
  z-index: 5;
}

ion-toolbar {
  --border-color: #fff;
}

ion-toolbar.primary-toolbar {
  --min-height: 80px;
  --background: var(--ion-color-white) url(../../assets/images/logo-main.svg) no-repeat 50% 50% /
    auto 30%;
}

.dark-header ion-toolbar.primary-toolbar {
  --background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    var(--ion-color-white) url(../../assets/images/logo-main.svg) no-repeat 50% 50% / auto 30%;
}

ion-toolbar.primary-toolbar ion-button.ion-color-primary {
  --border-width: 0;
  --border-color: transparent;
}

ion-header.static ion-toolbar:last-child {
  --min-height: var(--ion-toolbar-min-height);
  --border-width: 0;
}

ion-header.static.route-dashboard ion-toolbar.primary-toolbar {
  --background: var(--ion-color-white) url(../../assets/images/logo-main.svg) no-repeat 50% 50% /
    auto 30%;
}

ion-button.solo-button {
  --padding-start: 6px;
  --padding-end: 6px;
}

ion-button.basket-button {
  /* width: 45px; */
  height: 45px;
}

ion-button.basket-button > div > div > .badge,
ion-button.basket-button > div > div > .badge-small {
  position: absolute;
  bottom: 0;
  width: 20px;
  height: 20px;
  right: -4px;
  z-index: 1;
  padding: 4px 1px;
  font-size: 12px;
  border-radius: 60px;
}

ion-button.basket-button > div > div > .badge-small {
  width: 18px;
  height: 18px;
  background: var(--ion-color-primary);
  right: -2px;
}

ion-button.basket-button > div > div > ion-icon {
  fill: var(--ion-color-primary);
}

ion-button.basket-button > div > .basket-icon-wrapper {
  position: relative;
}

ion-button.basket-button > div > div > ion-icon {
  font-size: 30px;
}

.primary-toolbar .button {
  color: var(--ion-color-primary);
}

.image-button {
  flex: 1;
  background: transparent;
  --ripple-color: transparent;
}

.web ion-header:not(.route-order).static {
  position: absolute;
  right: 0;
  background: transparent none;
  min-height: var(--ion-toolbar-min-height);
  height: auto;
  margin: 0;
  z-index: 6;
  pointer-events: none;
}

.web ion-header.static ion-buttons {
  pointer-events: all;
}

.web ion-toolbar.primary-toolbar {
  --background: transparent url(../../assets/images/logo-main.svg) no-repeat 50% 50% / auto 110%;
}

.web ion-toolbar.primary-toolbar ion-buttons:first-of-type,
.web ion-toolbar.primary-toolbar ion-buttons:last-of-type,
.web ion-toolbar.dynamic-header ion-buttons:first-of-type {
  background-color: rgba(255, 255, 255, 0.5);
}

.web ion-toolbar.primary-toolbar ion-buttons:last-of-type ion-button {
  --padding-top: 12px;
  --padding-end: 12px;
  --padding-bottom: 12px;
  --padding-start: 12px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 50px;
  height: 50px;
}

.web ion-toolbar.primary-toolbar ion-button.image-button {
  display: none;
}

.web ion-button.basket-button > div > div > ion-badge.badge-small {
  --ion-color-primary: var(--ion-color-secondary);
  /* color: var(--ion-color-black); */
  color: var(--ion-color-white);
  font-size: 9px;
  min-width: 15px;
  text-align: center;
  --padding-top: 3px;
  --padding-bottom: 3px;
  --padding-start: 3px;
  --padding-end: 3px;
  width: auto;
  height: auto;
  right: 0;
  border-radius: 8px;
  padding: 3px;
  line-height: unset;
}

.web ion-button.basket-button > div > div:last-child {
  /* --ion-color-gray: var(--ion-color-secondary); */
  --ion-color-gray: var(--ion-color-gray);
}

@supports (-webkit-overflow-scrolling: touch) {
  /* iOS */
  ion-toolbar.primary-toolbar {
    --background: var(--ion-color-white) url(../../assets/images/logo-main.svg) no-repeat 50% 50% /
      auto 30%;
  }
}

.basket-icon-image {
  width: 25px;
  height: 25px;
}
