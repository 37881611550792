.bill-box {
    background: var(--okx-normal-text-color);
    padding: 10px;
    border-radius: 20px;
}

.bill-box ion-row {
    border-bottom: 1px solid var(--okx-border-color);
    color: var(--ion-color-tertiary)!important;
    font-weight: 400;
}